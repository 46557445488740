import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider as AppStateProvider } from './Containers/AppState'
import { CountryProvider } from './Containers/CountryProvider/CountryProvider'
import ApolloClientProvider from './GQL/ApolloClientProvider'
import './index.css'
// import reportWebVitals from './reportWebVitals'

ReactDOM.render(
    <React.StrictMode>
        <AppStateProvider>
            <CountryProvider>
                <ApolloClientProvider>
                    <App />
                </ApolloClientProvider>
            </CountryProvider>
        </AppStateProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
