import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const InboundIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.5 22.5H4.5C4.10218 22.5 3.72064 22.342 3.43934 22.0607C3.15804 21.7794 3 21.3978 3 21V12C3 11.6022 3.15804 11.2206 3.43934 10.9393C3.72064 10.658 4.10218 10.5 4.5 10.5H6.75V12H4.5V21H19.5V12H17.25V10.5H19.5C19.8978 10.5 20.2794 10.658 20.5607 10.9393C20.842 11.2206 21 11.6022 21 12V21C21 21.3978 20.842 21.7794 20.5607 22.0607C20.2794 22.342 19.8978 22.5 19.5 22.5Z"
                fill="currentColor"
            />
            <path d="M9.75 15H14.25V16.5H9.75V15Z" fill="currentColor" />
            <path
                d="M15.4425 6.4425L12.75 9.1275V1.5H11.25V9.1275L8.5575 6.4425L7.5 7.5L12 12L16.5 7.5L15.4425 6.4425Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
)
