import { useEffect, useState } from 'react'

type HookProps = {
    initialOnline: boolean
    onStatusChanged?: (online: boolean) => void
}

export const useIsOnline = (props?: HookProps) => {
    const [online, setOnline] = useState<boolean>(props?.initialOnline || true)

    useEffect(() => {
        const handleOffLineConnection = () => {
            setOnline(false)
            props?.onStatusChanged?.(false)
        }

        const handleOnLineChange = () => {
            setOnline(true)
            props?.onStatusChanged?.(true)
        }
        window.addEventListener('online', handleOnLineChange)
        window.addEventListener('offline', handleOffLineConnection)

        return () => {
            window.removeEventListener('online', handleOnLineChange)
            window.removeEventListener('offline', handleOffLineConnection)
        }
    }, [])

    return online
}
