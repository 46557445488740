import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const BellIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.9996 22.8535C12.4195 22.8438 12.8225 22.6859 13.1373 22.4078C13.4521 22.1296 13.6583 21.7491 13.7196 21.3335H10.2129C10.2759 21.7604 10.4918 22.1498 10.8204 22.4294C11.1491 22.709 11.5681 22.8597 11.9996 22.8535Z"
                    fill="#333333"
                />
                <path
                    d="M21.6732 18.5531C21.0303 17.98 20.4675 17.323 19.9999 16.5998C19.4894 15.6015 19.1834 14.5113 19.0999 13.3931V10.0998C19.0972 9.69974 19.0615 9.30061 18.9932 8.90644C18.4685 8.80155 17.9642 8.61244 17.4999 8.34644C17.6769 8.91634 17.7668 9.50969 17.7665 10.1064V13.3998C17.8482 14.7638 18.2235 16.0941 18.8665 17.2998C19.3268 18.0291 19.8729 18.7006 20.4932 19.2998H3.41987C4.04014 18.7006 4.58627 18.0291 5.04654 17.2998C5.68961 16.0941 6.06482 14.7638 6.14654 13.3998V10.0998C6.14303 9.32823 6.29174 8.56357 6.58416 7.84959C6.87659 7.1356 7.30697 6.4863 7.85068 5.93888C8.39439 5.39146 9.04074 4.95666 9.75272 4.65938C10.4647 4.3621 11.2283 4.20818 11.9999 4.20644C13.129 4.20734 14.2329 4.54127 15.1732 5.16644C15.0695 4.78576 15.0114 4.39414 14.9999 3.99977V3.57977C14.3038 3.23735 13.556 3.0121 12.7865 2.91311V2.07311C12.7865 1.83706 12.6928 1.61069 12.5259 1.44378C12.359 1.27687 12.1326 1.18311 11.8965 1.18311C11.6605 1.18311 11.4341 1.27687 11.2672 1.44378C11.1003 1.61069 11.0065 1.83706 11.0065 2.07311V2.94644C9.28368 3.18947 7.70692 4.0476 6.56735 5.36239C5.42778 6.67719 4.80236 8.35986 4.80654 10.0998V13.3931C4.72304 14.5113 4.41705 15.6015 3.90654 16.5998C3.44712 17.3213 2.89333 17.9783 2.25987 18.5531C2.18876 18.6156 2.13176 18.6925 2.09268 18.7787C2.0536 18.8649 2.03332 18.9585 2.0332 19.0531V19.9598C2.0332 20.1366 2.10344 20.3062 2.22847 20.4312C2.35349 20.5562 2.52306 20.6264 2.69987 20.6264H21.2332C21.41 20.6264 21.5796 20.5562 21.7046 20.4312C21.8296 20.3062 21.8999 20.1366 21.8999 19.9598V19.0531C21.8997 18.9585 21.8795 18.8649 21.8404 18.7787C21.8013 18.6925 21.7443 18.6156 21.6732 18.5531Z"
                    fill="#333333"
                />
                <path
                    d="M19.9993 7.33317C21.8403 7.33317 23.3327 5.84079 23.3327 3.99984C23.3327 2.15889 21.8403 0.666504 19.9993 0.666504C18.1584 0.666504 16.666 2.15889 16.666 3.99984C16.666 5.84079 18.1584 7.33317 19.9993 7.33317Z"
                    fill="#EB5757"
                />
            </svg>
        </SvgIcon>
    )
}
