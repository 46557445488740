import { Box, makeStyles } from '@material-ui/core'
import { FC, ReactNode } from 'react'

export interface PageProps {
    header: ReactNode
    footer?: ReactNode
    hasFooterPadding?: boolean
    showFooter?: boolean
    contentPaddingBottom?: number
    contentWrapperStyle?: React.CSSProperties
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            top: 0,
            left: 0,
            position: 'absolute',
            height: '100%',
            width: '100%',
        },
        header: {
            padding: theme.spacing(2),
            flexShrink: 1,
            flexGrow: 0,
            boxShadow: '0 -1px 5px rgb(0,0,0,.2)',
            minHeight: theme.spacing(10),
        },
        content: {
            overflow: 'auto',
            padding: theme.spacing(2),
            position: 'relative',
            flexGrow: 1,
            flexBasis: '100%',
        },
        contentWrapper: {
            position: 'relative',
            height: '100%',
            width: '100%',
        },
        footer: () => ({
            flexShrink: 1,
            flexGrow: 0,
            padding: theme.spacing(2),
        }),
    }),
    { name: 'Page' }
)

const Page: FC<PageProps> = (props) => {
    const { header, footer, showFooter, children, contentWrapperStyle } = props
    const classes = useStyles(props)

    return (
        <Box className={classes.root}>
            <>
                <Box className={classes.header}>{header}</Box>
                <Box className={classes.content} id="scroll-parent">
                    <div
                        className={classes.contentWrapper}
                        style={contentWrapperStyle}
                    >
                        {children}
                    </div>
                </Box>
                {showFooter && <Box className={classes.footer}>{footer}</Box>}
            </>
        </Box>
    )
}

export default Page
