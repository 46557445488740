import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import {
    createContext,
    FC,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useCountry } from '../Containers/CountryProvider/CountryProvider'
import { getLocalUserData } from '../utils/dataStore'

interface ApolloProviderValue {
    setApolloAccessToken(accessToken: string | undefined): void
}
const ApolloContext = createContext<ApolloProviderValue>({
    setApolloAccessToken: (accessToken: string | undefined) => {
        Function.prototype(accessToken)
    },
})

export const errorLink = onError((response) => {
    if (response.graphQLErrors) {
        response.graphQLErrors.map((error) =>
            console.log(
                `[GraphQL error]: Message: ${error.message}, Path: ${error.path}`
            )
        )
    }
    if (response.networkError) {
        console.log(`[Network error]: ${response.networkError}`)
    }
})
export const httpLink = new HttpLink({
    uri: process.env.REACT_APP_SALEOR_URL_UK,
})
export const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: new InMemoryCache(),
})
const ApolloClientProvider: FC = (props) => {
    const clientRef = useRef(apolloClient)
    const [apolloAccessToken, setApolloAccessToken] = useState<
        string | undefined
    >()
    const { userCountry, SALEOR_URL } = useCountry()
    const updateLinks = useCallback(() => {
        const httpLink = new HttpLink({
            uri: SALEOR_URL,
        })

        const authLink = new ApolloLink((operation, nextLink) => {
            const accessToken = getLocalUserData()?.accessToken

            operation.setContext(
                (context: { headers: { Authorization?: string } }) => {
                    const nextHeaders = { ...context.headers }

                    if (accessToken) {
                        nextHeaders.Authorization = `JWT ${accessToken}`
                    } else if (nextHeaders.Authorization) {
                        delete nextHeaders.Authorization
                    }

                    return { headers: nextHeaders }
                }
            )

            return nextLink(operation)
        })

        apolloClient.setLink(ApolloLink.from([authLink, errorLink, httpLink]))
    }, [userCountry])

    useEffect(() => {
        updateLinks()
    }, [userCountry, apolloAccessToken, updateLinks])

    const value: ApolloProviderValue = {
        setApolloAccessToken,
    }

    return (
        <ApolloContext.Provider value={value}>
            <ApolloProvider client={clientRef.current}>
                {props.children}
            </ApolloProvider>
        </ApolloContext.Provider>
    )
}

export function useApolloProviderContext() {
    return useContext(ApolloContext)
}

export default ApolloClientProvider
