import { OfflineErrorImage } from '../../Components/Svg/OfflineErrorImage'
import ErrorPageContainerStyle from './style'

const OfflinePage = () => {
    return (
        <ErrorPageContainerStyle>
            <OfflineErrorImage />
            <div className="error-detail">
                <div className="error-title">Connection error!</div>
                <span className="error-desc">
                    Check the internet connection or any other error message.
                </span>
            </div>
        </ErrorPageContainerStyle>
    )
}

export default OfflinePage
