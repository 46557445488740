import { makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useIsOnline } from './useInOnline'

interface Props {
    initialOnline?: boolean
    children?: React.ReactNode
}

export const useStyles = makeStyles(
    (theme) => ({
        root: {
            position: 'absolute',
            top: theme.spacing(2),
            left: theme.spacing(2),
            right: theme.spacing(2),
        },
    }),
    { name: 'OrderListItem' }
)

const InternetCheck = ({ children, initialOnline = true }: Props) => {
    const classes = useStyles()
    const online = useIsOnline({ initialOnline })

    return (
        <>
            {!online && (
                <section className={classes.root}>
                    <Alert severity="error">
                        <strong>No Internet: </strong>Please check your internet
                        connection
                    </Alert>
                </section>
            )}

            {children}
        </>
    )
}

export default InternetCheck
