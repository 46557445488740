import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const PurchaseIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 0.25V19.75H12V18.25H1.5V1.75H9V6.25H13.5V7.75H15V5.2L14.775 4.975L10.275 0.475L10.05 0.25H0ZM10.5 2.8L12.45 4.75H10.5V2.8ZM3 7.75V9.25H12V7.75H3ZM15.75 9.25V10.75C14.475 10.975 13.5 12.025 13.5 13.375C13.5 14.875 14.625 16 16.125 16H16.875C17.475 16 18 16.525 18 17.125C18 17.725 17.475 18.25 16.875 18.25H14.25V19.75H15.75V21.25H17.25V19.75C18.525 19.525 19.5 18.475 19.5 17.125C19.5 15.625 18.375 14.5 16.875 14.5H16.125C15.525 14.5 15 13.975 15 13.375C15 12.775 15.525 12.25 16.125 12.25H18.75V10.75H17.25V9.25H15.75ZM3 11.5V13H8.25V11.5H3ZM9.75 11.5V13H12V11.5H9.75ZM3 14.5V16H8.25V14.5H3ZM9.75 14.5V16H12V14.5H9.75Z"
                    fill={props.htmlColor || '#444444'}
                />
            </svg>
        </SvgIcon>
    )
}
