import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const StockIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path
                    d="M12.875 5.75C14.1867 5.75 15.25 4.68668 15.25 3.375C15.25 2.06332 14.1867 1 12.875 1C11.5633 1 10.5 2.06332 10.5 3.375C10.5 4.68668 11.5633 5.75 12.875 5.75Z"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1 3.37463H10.5"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.25 3.37463H20"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.75 12.8757C7.06168 12.8757 8.125 11.8124 8.125 10.5007C8.125 9.18906 7.06168 8.12573 5.75 8.12573C4.43832 8.12573 3.375 9.18906 3.375 10.5007C3.375 11.8124 4.43832 12.8757 5.75 12.8757Z"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1 10.5004H3.375"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.125 10.5004H20"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.4375 19.9996C17.7492 19.9996 18.8125 18.9363 18.8125 17.6246C18.8125 16.313 17.7492 15.2496 16.4375 15.2496C15.1258 15.2496 14.0625 16.313 14.0625 17.6246C14.0625 18.9363 15.1258 19.9996 16.4375 19.9996Z"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1 17.6243H14.0625"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.8125 17.6243H20"
                    stroke={props.htmlColor || '#444444'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    )
}
