const ALLOWED_USER_ROLES = [
    'superPowerUser',
    'powerUser',
    'procurementUser',
    'warehouseManager',
]

export const canResolveDiscrepancies = (userRole: string) => {
    return ALLOWED_USER_ROLES.includes(userRole)
}
