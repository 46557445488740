import { makeStyles } from '@material-ui/core'

export default makeStyles({
    container: {
        backgroundColor: '#51b7f6',
        position: 'fixed',
        height: '100%',
        width: '100%',
    },
    circularProgress: {
        color: 'white',
    },
    content: {
        width: '100%',
        padding: '0 48px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'table',
    },
    message: {
        color: 'white',
    },
    button: {
        background: 'white',
        color: '#51b7f6',
        padding: '8px 24px',
    },
})
