import { WarehouseConfig } from '@quickcommerceltd/zapp-app-utils'
import { useEffect, useMemo } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { auth, db } from '../../firebase'
import { UserDetails } from '../../types/UserDetails'
import { getLocalUserData, setWarehouse } from '../../utils/dataStore'
import { useCountry } from '../CountryProvider/CountryProvider'

const useProvider = () => {
    const [user = null] = useAuthState(auth)
    const country = useCountry()
    const userCountry = country.userCountry.toLocaleLowerCase()
    const [userDetails] = useDocumentData<UserDetails>(
        user && userCountry
            ? db.collection(`${userCountry}_users`).doc(user.uid)
            : undefined
    )

    const [warehouseConfig] = useDocumentData<WarehouseConfig>(
        userDetails?.warehouseId
            ? db.collection('warehouseConfigs').doc(userDetails.warehouseId)
            : undefined
    )

    useEffect(() => {
        // TODO Remove this once we have removed the usage of localStorage for user warehouse
        setWarehouse(warehouseConfig)
    }, [warehouseConfig])

    const userAccessToken = getLocalUserData()?.accessToken

    const value = useMemo(() => {
        return {
            user,
            userAccessToken,
            userDetails,
            userCountry,
            warehouseId: userDetails?.warehouseId,
            warehouseConfig,
        }
    }, [user, userAccessToken, userCountry, userDetails, warehouseConfig])

    return { value }
}

export default useProvider
