export const BackspaceIcon = () => (
    <svg
        width="17"
        height="12"
        viewBox="0 0 17 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.14592 3.14592C8.19236 3.09935 8.24754 3.06241 8.30828 3.0372C8.36903 3.012 8.43415 2.99902 8.49992 2.99902C8.56568 2.99902 8.63081 3.012 8.69155 3.0372C8.7523 3.06241 8.80747 3.09935 8.85392 3.14592L10.9999 5.29292L13.1459 3.14592C13.2398 3.05203 13.3671 2.99929 13.4999 2.99929C13.6327 2.99929 13.76 3.05203 13.8539 3.14592C13.9478 3.2398 14.0005 3.36714 14.0005 3.49992C14.0005 3.63269 13.9478 3.76003 13.8539 3.85392L11.7069 5.99992L13.8539 8.14592C13.9478 8.2398 14.0005 8.36714 14.0005 8.49992C14.0005 8.63269 13.9478 8.76003 13.8539 8.85392C13.76 8.9478 13.6327 9.00055 13.4999 9.00055C13.3671 9.00055 13.2398 8.9478 13.1459 8.85392L10.9999 6.70692L8.85392 8.85392C8.76016 8.9478 8.63295 9.0006 8.50027 9.0007C8.36759 9.00079 8.2403 8.94817 8.14642 8.85442C8.05253 8.76066 7.99973 8.63345 7.99964 8.50077C7.99954 8.36809 8.05216 8.2408 8.14592 8.14692L10.2929 5.99992L8.14592 3.85392C8.09935 3.80747 8.06241 3.7523 8.0372 3.69155C8.012 3.63081 7.99902 3.56568 7.99902 3.49992C7.99902 3.43415 8.012 3.36903 8.0372 3.30828C8.06241 3.24754 8.09935 3.19236 8.14592 3.14592Z"
            fill="#51b7f6"
        />
        <path
            d="M5.5863 0.664C6.04828 0.237046 6.65424 -5.22594e-05 7.2833 8.63997e-09H14.5003C15.1633 8.63997e-09 15.7992 0.263392 16.2681 0.732233C16.7369 1.20107 17.0003 1.83696 17.0003 2.5V9.5C17.0003 10.163 16.7369 10.7989 16.2681 11.2678C15.7992 11.7366 15.1633 12 14.5003 12H7.2833C6.65424 12.0001 6.04828 11.763 5.5863 11.336L1.7993 7.836C1.54602 7.60192 1.34391 7.31797 1.20568 7.002C1.06745 6.68603 0.996094 6.34488 0.996094 6C0.996094 5.65512 1.06745 5.31397 1.20568 4.998C1.34391 4.68203 1.54602 4.39808 1.7993 4.164L5.5863 0.664ZM7.2833 1C6.90585 1.00018 6.54235 1.14266 6.2653 1.399L2.4783 4.899C2.32636 5.03944 2.20513 5.2098 2.12221 5.39937C2.03929 5.58893 1.99649 5.7936 1.99649 6.0005C1.99649 6.2074 2.03929 6.41207 2.12221 6.60163C2.20513 6.7912 2.32636 6.96156 2.4783 7.102L6.2653 10.602C6.5425 10.858 6.90599 11.0001 7.2833 11H14.5003C14.8981 11 15.2797 10.842 15.561 10.5607C15.8423 10.2794 16.0003 9.89782 16.0003 9.5V2.5C16.0003 2.10218 15.8423 1.72064 15.561 1.43934C15.2797 1.15804 14.8981 1 14.5003 1H7.2833Z"
            fill="#51b7f6"
        />
    </svg>
)
