import { firebase, zimBackendAuth, zimBackendDb } from '../firebase'
import { getDeviceId } from './dataStore'
import { log } from './logHelper'

const COLLECTION_DEVICES = 'devices'

interface Props {
    isOnline: boolean
    pushToken?: string
    userId?: string
    userEmail?: string
    userName?: string
    warehouseId?: string
}

export const setDeviceStatus = async ({
    isOnline,
    pushToken,
    userId,
    userEmail,
    userName,
    warehouseId,
}: Props) => {
    if (!zimBackendAuth?.currentUser) {
        return
    }

    const lastSeen = firebase.firestore.FieldValue.serverTimestamp()
    const id = getDeviceId()
    const device = {
        id,
        userEmail,
        userName,
        userId,
        warehouseId,
        pushToken,
        lastSeen,
        isOnline,
    }

    try {
        await zimBackendDb
            .collection(COLLECTION_DEVICES)
            .doc(device.id)
            .set(device, { merge: true })
    } catch (error) {
        log('deviceStatus', 'firestore-error', error)
    }
}
