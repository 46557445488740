import {
    Box,
    Container,
    IconButton,
    List,
    ListItemText,
    makeStyles,
    Typography,
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import { useState } from 'react'
import { ROUTES } from '../../consts/Routes'
import { useAppState } from '../../Containers/AppState'
import { canResolveDiscrepancies } from '../../Containers/DiscrepancySweeper/_utils'
import { useFeatureFlag } from '../../Containers/FeatureFlag'
import { useLogout } from '../../firebase/useLogout'
import { WarehouseType } from '../../types'
import { isDCWarehouse } from '../../utils/dc'
import {
    UpdateIcon as ChangeWarehouseIcon,
    CheckSquareIcon,
    CrossHairIcon,
    HamburgerBarIcon,
    InboundIcon,
    LocationsIcon,
    LogoutIcon,
    MoveIcon,
    TruckIcon,
} from '../Svg'
import MenuItem from './MenuItem'

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        flex: 1,
        height: '100vh',
        width: '80vw',
    },
    listContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: `0 ${theme.spacing(2)}px`,
    },
    userName: {
        fontSize: '18px',
        lineHeight: 1.2,
        color: '#fff',
        fontWeight: 400,
    },
    userEmail: {
        fontSize: '14px',
        color: '#fff',
        fontWeight: 400,
        lineHeight: 1,
    },
    avatar: {
        height: '56px',
        width: '56px',
        marginRight: '15px',
        backgroundColor: '#000',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarText: {
        color: '#fff',
        fontWeight: 'bold',
    },
    userContainer: {
        backgroundColor: '#0B65CF',
        padding: `${theme.spacing(1)}px 0`,
    },
    menuItem: {
        fontSize: '18px',
        color: '#444444',
        fontWeight: 500,
        cursor: 'pointer',
    },
    menuItemIconContainer: {
        minWidth: 'auto',
        marginRight: '15px',
    },
    menuContainer: {
        flex: 7,
        overflow: 'scroll',
    },
    signOutContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    listItem: {
        marginTop: `${theme.spacing(1)}px`,
    },
    sblBadge: {
        fontSize: '8px',
        marginLeft: '8px',
        borderRadius: '4px',
        border: '1px solid #0B65CF',
        padding: '1px 4px',
        color: '#0B65CF',
    },
    hamburger: {
        position: 'absolute',
        height: theme.spacing(10),
        width: theme.spacing(6),
        borderRadius: 0,
        right: 0,
        zIndex: 100,
        top: 0,
    },
}))

const SideDrawer = () => {
    const classes = useStyles()
    const { userDetails, warehouseConfig } = useAppState()

    const { logout } = useLogout()

    const {
        values: {
            isPickingInStoresEnabled,
            isAssignSkuEnabled,
            isStockByLocationEnabled,
            isZippStockAdjustmentEnabled,
            isExpirySweeperEnabled,
            isDiscrepancySweeperEnabled,
        },
    } = useFeatureFlag([
        'isPickingInStoresEnabled',
        'isAssignSkuEnabled',
        'isStockByLocationEnabled',
        'isZippStockAdjustmentEnabled',
        'isExpirySweeperEnabled',
        'isDiscrepancySweeperEnabled',
    ])

    const addSblBadge = !!isStockByLocationEnabled && (
        <div className={classes.sblBadge}>SBL</div>
    )

    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)

    if (!userDetails) {
        return null
    }

    return (
        <>
            <IconButton className={classes.hamburger} onClick={toggle}>
                <HamburgerBarIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={isOpen}
                onClose={toggle}
                transitionDuration={100}
            >
                <Container className={classes.drawerContainer}>
                    <Box className={classes.userContainer}>
                        <List className={classes.listContainer}>
                            <Box className={classes.avatar}>
                                <Typography className={classes.avatarText}>
                                    {userDetails.name
                                        .split(' ')
                                        .map((name) => name.charAt(0))}
                                </Typography>
                            </Box>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.userName}
                                    >
                                        {userDetails.name}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.userEmail}
                                    >
                                        {userDetails.email}
                                    </Typography>
                                }
                            ></ListItemText>
                        </List>
                    </Box>
                    <Box className={classes.menuContainer} onClick={toggle}>
                        <List>
                            <MenuItem
                                icon={<InboundIcon />}
                                badge={addSblBadge}
                                to={
                                    isStockByLocationEnabled
                                        ? ROUTES.INBOUND_V2
                                        : ROUTES.INBOUND_PO_SEARCH
                                }
                            >
                                Inbound
                            </MenuItem>

                            {isStockByLocationEnabled && (
                                <MenuItem
                                    icon={<TruckIcon />}
                                    badge={addSblBadge}
                                    to={
                                        warehouseConfig?.type ===
                                        WarehouseType.STORE
                                            ? ROUTES.PUTAWAY_ALL
                                            : ROUTES.PUTAWAY
                                    }
                                >
                                    Putaway
                                </MenuItem>
                            )}

                            {isDCWarehouse() ? (
                                <MenuItem
                                    icon={<InboundIcon />}
                                    badge={addSblBadge}
                                    to={
                                        isStockByLocationEnabled
                                            ? ROUTES.PICKING_V2
                                            : ROUTES.PICKING
                                    }
                                >
                                    Picking
                                </MenuItem>
                            ) : isPickingInStoresEnabled ? (
                                <MenuItem
                                    icon={<InboundIcon />}
                                    badge={addSblBadge}
                                    to={
                                        isStockByLocationEnabled
                                            ? ROUTES.PICKING_V2
                                            : ROUTES.PICKING
                                    }
                                >
                                    Stock Transfer
                                </MenuItem>
                            ) : undefined}
                            {isZippStockAdjustmentEnabled && (
                                <MenuItem
                                    icon={<LocationsIcon />}
                                    badge={addSblBadge}
                                    to={
                                        isStockByLocationEnabled
                                            ? ROUTES.STOCK_ADJUSTMENT_V2_ADJUST_STOCK
                                            : ROUTES.CYCLE_COUNT
                                    }
                                >
                                    Stock Adjustment
                                </MenuItem>
                            )}

                            {isStockByLocationEnabled && (
                                <MenuItem
                                    icon={<MoveIcon />}
                                    badge={addSblBadge}
                                    to={ROUTES.STOCK_ADJUSTMENT_V2_MOVE_SKU}
                                >
                                    Move SKU
                                </MenuItem>
                            )}

                            {isAssignSkuEnabled && (
                                <MenuItem
                                    icon={<CrossHairIcon />}
                                    badge={addSblBadge}
                                    to={ROUTES.ASSIGN_SKU_LOCATION}
                                >
                                    Assign SKU Items
                                </MenuItem>
                            )}

                            {isStockByLocationEnabled && (
                                <MenuItem
                                    icon={<CrossHairIcon />}
                                    badge={addSblBadge}
                                    to={ROUTES.STOCK_MOVEMENT_EXPLORER}
                                >
                                    Stock Explorer
                                </MenuItem>
                            )}

                            {isExpirySweeperEnabled && (
                                <MenuItem
                                    icon={<CheckSquareIcon />}
                                    badge={addSblBadge}
                                    to={ROUTES.EXPIRY_SWEEPER}
                                >
                                    Expiry Sweeper
                                </MenuItem>
                            )}

                            {isDiscrepancySweeperEnabled &&
                                canResolveDiscrepancies(
                                    userDetails.userRole
                                ) && (
                                    <MenuItem
                                        icon={<CheckSquareIcon />}
                                        badge={addSblBadge}
                                        to={ROUTES.DISCREPANCY_SWEEPER}
                                    >
                                        Discrepancy Sweeper
                                    </MenuItem>
                                )}

                            <MenuItem
                                icon={<ChangeWarehouseIcon />}
                                to={ROUTES.WAREHOUSE_SECURITY}
                            >
                                Change Store
                            </MenuItem>
                        </List>
                    </Box>
                    <Box className={classes.signOutContainer}>
                        <MenuItem icon={<LogoutIcon />} onClick={logout}>
                            Sign Out
                        </MenuItem>
                    </Box>
                </Container>
            </Drawer>
        </>
    )
}

export default SideDrawer
