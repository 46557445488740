export const ROUTES = {
    LOGIN: '/login',
    SIGNUP_COMPLETE: '/signup-complete',
    PROFILE: '/profile',
    INDEX: '/',
    QUEUE_LISTENING: '/queue',
    TASK_PERMALINK: '/task/:taskId',
    ORDERS: '/orders',
    PICKLIST: '/pick-list/:poNumber',
    ORDERS_WRONG_ITEM_SCANNED: '/orders/wrong-item-scanned',
    ORDERS_ASSIGN_BARCODE: '/orders/assign-barcode',
    WAREHOUSE_SECURITY: '/warehouse-security',
    WAREHOUSE_SELECTION: '/warehouse-selection/:pin',
    WAREHOUSE_LOCATIONS: '/locations',
    DASHBOARD: '/dashboard',
    UPDATE_PRODUCT_LIST: '/update-product-list',
    UPDATE_PRODUCT_LIST_TABLE: '/update-product-list-table',
    PURCHASE_ORDERS: '/purchase-orders',
    PURCHASE_ORDER_DETAIL: '/purchase-order/:poNumber',
    STOCK_ADJUSTMENT: '/stock-adjustment/:id?',
    STOCK_ADJUSTMENT_V2_ADJUST_STOCK: '/adjust-stock',
    STOCK_ADJUSTMENT_V2_MOVE_SKU: '/move-sku',
    USERS: '/users',
    INBOUND_PO_NO: '/inbound',
    PICKING: '/picking',
    SKU_ANALYSIS: '/sku-analysis',
    CYCLE_COUNT: '/cycle-count',
    ASSIGN_SKU_LOCATION: '/assign-sku-location',
    INBOUND_PO_SEARCH: '/inbound-po-search',
    INBOUND_PO_ITEMS: '/inbound/:po',
    INBOUND_V2: '/inboundV2',
    INBOUND_V2_PURCHASE_ORDER: '/inboundV2/:purchaseOrderId',
    INBOUND_V2_PRODUCT_LIST: '/inboundV2/:purchaseOrderId/products',
    INBOUND_V2_ITEM: '/inboundV2/:purchaseOrderId/products/:id',
    PO_SKU_LOCATIONS: '/po-sku-locations',
    PO_SKU_LOCATIONS_SEARCH: '/po-sku-locations/:orderNumber',
    PUTAWAY: '/putaway',
    PUTAWAY_ALL: '/putaway/all',
    PUTAWAY_ITEMS: '/putaway/:cageNumber',
    LOCATIONS_V2: '/locations-v2',
    LOCATIONS_V3: '/locations-v3',
    PICKING_V2: '/pickingV2',
    PICKING_V2_PRODUCT_LIST:
        '/pickingV2/:purchaseOrderId/:containerId/products',
    PICKING_V2_SUCCESS: '/pickingV2/:purchaseOrderId/:containerId/success',
    STOCK_MOVEMENT_EXPLORER: '/stock-movement-explorer',
    STOCK_MOVEMENT_EXPLORER_DETAILS: '/stock-movement-explorer/:id',
    DISCREPANCY_SWEEPER: '/discrepancy-sweeper',
    DISCREPANCY_SWEEPER_DETAILS: '/discrepancy-sweeper/:locationId',
    STOCK_CHECKS: '/stock-checks',
    DEBUGGING: '/debugging',
    EXPIRY_SWEEPER: '/expiry-sweeper',
}
