import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const TruckIcon = (props: SvgIconProps) => (
    <SvgIcon
        {...props}
        width={24}
        height={16}
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m23.25 6.244-.002-.023a.753.753 0 0 0-.004-.062l-.004-.026a.747.747 0 0 0-.04-.15l-.004-.012-.004-.011-.001-.003-1.306-3.264a1.493 1.493 0 0 0-1.393-.943H17.25V1a.75.75 0 0 0-.75-.75H2.25a1.502 1.502 0 0 0-1.5 1.5v10.5a1.502 1.502 0 0 0 1.5 1.5h1.22a3 3 0 0 0 5.81 0h5.44a3.001 3.001 0 0 0 5.81 0h1.22a1.502 1.502 0 0 0 1.5-1.5V6.244ZM20.492 3.25l.9 2.25H17.25V3.25h3.242Zm-4.742-1.5v6H2.25v-6h13.5Zm-13.5 7.5h13.5v1.41a3.005 3.005 0 0 0-1.03 1.59H9.28a3 3 0 0 0-5.81 0H2.25v-3Zm4.125 5.25a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm11.25 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm4.125-2.25h-1.22a2.993 2.993 0 0 0-3.28-2.226V7h4.5v5.25Z"
            fill="currentColor"
        />
    </SvgIcon>
)
