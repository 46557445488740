import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core'

import { REQUEST_STATUS } from '../../consts/requestStatus'

import useStyles from './styles'
import type { Props } from './types'

const FullScreenSpinner = ({
    requestStatus,
    onTryAgain,
    message,
    children,
}: Props) => {
    const classes = useStyles()
    const isPending = requestStatus === REQUEST_STATUS.PENDING
    const isRejected = requestStatus === REQUEST_STATUS.REJECTED
    const isFulfilled = requestStatus === REQUEST_STATUS.FULFILLED

    if (isFulfilled) {
        return <>{children}</>
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    {isPending && (
                        <Grid item>
                            <CircularProgress
                                className={classes.circularProgress}
                                data-testid="circular-progress"
                            />
                        </Grid>
                    )}

                    {message && (
                        <Grid item>
                            <Typography
                                variant="h6"
                                align="center"
                                className={classes.message}
                            >
                                {message}
                            </Typography>
                        </Grid>
                    )}

                    {isRejected && (
                        <Grid item>
                            <Button
                                className={classes.button}
                                disableElevation
                                onClick={onTryAgain}
                            >
                                Try Again
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}

export default FullScreenSpinner
