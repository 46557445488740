export const BarcodeBlackIcon = () => {
    return (
        <svg
            width="110"
            height="98"
            viewBox="0 0 110 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.24967 5.66667V32.75H17.083V11.0833H38.7497V0.25H11.6663C8.67092 0.25 6.24967 2.67125 6.24967 5.66667ZM103.75 32.75V5.66667C103.75 2.67125 101.328 0.25 98.333 0.25H71.2497V11.0833H92.9163V32.75H103.75ZM92.9163 86.9167H71.2497V97.75H98.333C101.328 97.75 103.75 95.3288 103.75 92.3333V65.25H92.9163V86.9167ZM38.7497 97.75V86.9167H17.083V65.25H6.24967V92.3333C6.24967 95.3288 8.67092 97.75 11.6663 97.75H38.7497ZM0.833008 43.5833H109.166V54.4167H0.833008V43.5833Z"
                fill="#454545"
            />
        </svg>
    )
}
