import { OrderDetails } from '@quickcommerceltd/zapp-app-utils'
import React, { useContext, useMemo, useReducer } from 'react'
import { Product } from '../../types'

// TODO: i think we don't need and use this anymore. Remove it.

// TODO: i think we don't need and use this anymore. Remove it.

type State = {
    order: OrderDetails | undefined
    products: Product[]
}

const initialState: State = {
    order: undefined,
    products: [],
}

type Action =
    | { type: 'setOrder'; order: OrderDetails }
    | { type: 'setProducts'; products: Product[] }
    | { type: 'addToBasket'; index: number }
    | { type: 'removeFromBasket'; product: Product }
    | { type: 'setOutOfStock'; product: Product; outOfStock?: boolean }
    | { type: 'unsetOutOfStock'; product: Product; outOfStock?: boolean }
    | { type: 'setBarCode'; product: Product; barcode: string }
    | { type: 'undo'; product: Product }
    | { type: 'clear' }

const StateContext = React.createContext<State | undefined>(undefined)
const DispatchContext = React.createContext<React.Dispatch<Action> | undefined>(
    undefined
)

function reducer(state: State, action: Action) {
    switch (action.type) {
        case 'setOrder': {
            return { ...state, order: action.order }
        }
        case 'setProducts': {
            return { ...state, products: action.products }
        }
        case 'addToBasket': {
            const index = action.index
            const product = state.products[index]
            if (product.quantityInBasket < product.quantity) {
                // debugger
                product.quantityInBasket = product.quantityInBasket + 1
                // product.quantityInBasket = 1
            }
            product.outOfStock = false
            const products = JSON.parse(JSON.stringify(state.products))
            return { ...state, products: products }
        }
        case 'removeFromBasket': {
            const product = action.product
            product.quantityInBasket -= 1
            return { ...state, products: [...state.products] }
        }
        case 'setOutOfStock': {
            const product = action.product
            product.outOfStock = true
            return { ...state, products: [...state.products] }
        }
        case 'unsetOutOfStock': {
            const product = action.product
            product.outOfStock = false
            return { ...state, products: [...state.products] }
        }
        case 'setBarCode': {
            const product = action.product
            if (!product.barcodes) {
                product.barcodes = []
            }
            product.barcodes = [...product.barcodes, action.barcode]
            const products = JSON.parse(JSON.stringify(state.products))
            return { ...state, products }
        }
        // undo either outOfStock or remove one item from basket
        case 'undo': {
            const product = action.product
            if (product.outOfStock) {
                product.outOfStock = false
            } else {
                if (product.quantityInBasket > 0) {
                    product.quantityInBasket -= 1
                }
            }
            return { ...state, products: [...state.products] }
        }
        case 'clear': {
            return { ...state, products: [], order: undefined }
        }
        default:
            return state
        // default: {
        //   throw new Error(`Unhandled action type: ${action.type}`)
        // }
    }
}
const OrdersProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}
/**
 * Use the state of this Provider
 * Example usage:
 *
 * const { order, products } = useOrders()
 */
function useOrders() {
    const context = useContext(StateContext)
    if (context === undefined) {
        throw new Error(
            'useOrders must be used within a OrdersProvider-Provider'
        )
    }
    return context
}

/**
 * Returns dispatch function.
 * Example usage:
 *
 * const dispatch = useOrdersDispatch()
 *
 * dispatch({type: "setOrder", order})
 */
function useOrdersDispatch() {
    const context = useContext(DispatchContext)
    if (context === undefined) {
        throw new Error(
            'useOrdersActions must be used within a OrdersProvider-Provider'
        )
    }
    return context
}

/**
 * Returns all actions already binded to dispatch function.
 * Example usage:
 *
 * const {reset, setOrder} = useOrdersActions()
 * // Call directly
 * reset()
 * setOrder(order)
 */
function useOrdersActions() {
    const dispatch = useContext(DispatchContext)
    if (dispatch === undefined) {
        throw new Error(
            'useOrdersActions must be used within a OrdersProvider-Provider'
        )
    }

    const actions = useMemo(() => {
        return {
            reset: () => dispatch({ type: 'clear' }),
            setOrder: (order: OrderDetails) =>
                dispatch({ type: 'setOrder', order }),
            addToBasket: (index: number) =>
                dispatch({ type: 'addToBasket', index }),
            setOutOfStock: (product: Product) =>
                dispatch({
                    type: 'setOutOfStock',
                    product,
                }),
            unsetOutOfStock: (product: Product) =>
                dispatch({
                    type: 'unsetOutOfStock',
                    product,
                }),
            undo: (product: Product) =>
                dispatch({
                    type: 'undo',
                    product,
                }),
            setBarCode: ({
                product,
                barcode,
            }: {
                product: Product
                barcode: string
            }) =>
                dispatch({
                    type: 'setBarCode',
                    product,
                    barcode,
                }),
        }
    }, [dispatch])

    return actions
}

export { OrdersProvider, useOrders, useOrdersDispatch, useOrdersActions }
