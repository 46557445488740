interface ILogo {
    fillColor?: string
}

export const Logo = (props: ILogo) => {
    const { fillColor } = props

    return (
        <svg
            width={154}
            height={37}
            viewBox="0 0 931 287"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 89.9785L298.028 90.0684L278.979 116.706H0V89.9785Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M61.627 143.436L259.889 143.524L240.845 170.163H61.627V143.436Z"
                fill="url(#paint1_linear)"
            />
            <path
                d="M122.617 195.268L221.754 195.356L202.709 221.995H122.617V195.268Z"
                fill="url(#paint2_linear)"
            />
            <path
                d="M244.981 286.711C239.924 286.711 235.076 284.748 231.5 281.255C227.924 277.762 225.914 273.023 225.914 268.083V242.165C225.914 240.982 226.028 239.802 226.256 238.639C226.543 235.375 227.709 232.243 229.636 229.562L348.892 63.1738H247.468C242.411 63.1738 237.563 61.2113 233.987 57.7179C230.411 54.2244 228.401 49.4861 228.401 44.5456V18.6282C228.401 13.6877 230.411 8.94934 233.987 5.45589C237.563 1.96243 242.411 0 247.468 0H424.046C429.103 0 433.953 1.96243 437.529 5.45589C441.105 8.94934 443.114 13.6877 443.114 18.6282V42.1688C443.495 45.4194 442.991 48.7103 441.652 51.7091C440.923 53.4133 439.939 55.0025 438.732 56.4239L318.968 223.537H421.563C426.62 223.537 431.47 225.5 435.045 228.993C438.621 232.486 440.63 237.225 440.63 242.165V268.083C440.63 273.023 438.621 277.762 435.045 281.255C431.47 284.748 426.62 286.711 421.563 286.711H244.981Z"
                fill={fillColor ? fillColor : '#02BAFF'}
            />
            <path
                d="M504.515 287H535.476C549.327 287 557.474 279.259 557.474 265.001V23.8325C557.474 9.57419 549.327 1.83398 535.476 1.83398H504.515C490.257 1.83398 482.517 9.57419 482.517 23.8325V265.001C482.517 279.259 490.257 287 504.515 287Z"
                fill={fillColor ? fillColor : '#02BAFF'}
            />
            <path
                d="M613.384 287H643.53C657.381 287 665.529 279.667 666.751 266.223L677.75 134.232L729.895 266.631C734.376 278.852 742.523 284.148 755.152 284.148H767.781C780.41 284.148 788.557 278.852 793.038 266.631L845.183 134.232L856.182 266.223C856.997 279.667 865.144 287 878.995 287H909.549C924.214 287 931.955 278.852 930.325 263.779L907.919 22.6103C907.105 9.16682 898.957 1.83398 885.513 1.83398H851.701C839.072 1.83398 831.332 7.12992 826.443 18.9439L761.263 179.451L696.489 18.9439C691.601 7.12992 683.453 1.83398 671.232 1.83398H637.419C623.569 1.83398 615.828 9.16682 614.606 22.6103L592.2 263.779C590.978 278.852 598.718 287 613.384 287Z"
                fill={fillColor ? fillColor : '#02BAFF'}
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1={0}
                    y1="103.342"
                    x2="289.087"
                    y2="103.342"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity={0}
                    />
                    <stop
                        offset="0.133"
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity={0}
                    />
                    <stop
                        offset="0.512"
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity="0.7"
                    />
                    <stop
                        offset={1}
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                    />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="61.627"
                    y1="156.799"
                    x2="253.942"
                    y2="156.799"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity={0}
                    />
                    <stop
                        offset="0.133"
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity={0}
                    />
                    <stop
                        offset="0.512"
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity="0.7"
                    />
                    <stop
                        offset={1}
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                    />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="122.617"
                    y1="208.631"
                    x2="218.78"
                    y2="208.631"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity={0}
                    />
                    <stop
                        offset="0.133"
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity={0}
                    />
                    <stop
                        offset="0.512"
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                        stopOpacity="0.7"
                    />
                    <stop
                        offset={1}
                        stopColor={fillColor ? fillColor : '#02BAFF'}
                    />
                </linearGradient>
            </defs>
        </svg>
    )
}
