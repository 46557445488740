export const ZappLogo = () => {
    return (
        <svg
            width="200"
            height="59"
            viewBox="0 0 200 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M82.6347 43.2853C81.448 43.0467 80.294 42.6961 79.1924 42.2396C78.093 41.7834 77.0492 41.2293 76.0774 40.586C75.0945 39.9355 74.1854 39.2025 73.3628 38.3974C72.5246 37.5759 71.775 36.6873 71.1236 35.7432C70.4561 34.7751 69.8901 33.7555 69.4327 32.6968C68.9617 31.6054 68.6039 30.4786 68.3639 29.3302C67.8672 26.9405 67.8672 24.4911 68.3639 22.1014C68.6039 20.9531 68.9615 19.8263 69.4325 18.735C69.8899 17.6763 70.4559 16.6566 71.1235 15.6886C71.7748 14.7444 72.5245 13.8557 73.3628 13.0341C74.1852 12.2286 75.0941 11.4952 76.0769 10.8443C77.0487 10.201 78.0924 9.64695 79.1919 9.19068C80.2937 8.73462 81.4479 8.38445 82.6347 8.14624C85.0674 7.66068 87.5924 7.66068 90.0251 8.14624C91.2118 8.38489 92.3659 8.73546 93.4675 9.1919C94.4452 9.59701 95.379 10.0798 96.2574 10.6342V9.84848C96.2574 9.10679 96.5915 8.39548 97.1862 7.87103C97.781 7.34658 98.5876 7.05194 99.4287 7.05194H104.668C105.509 7.05194 106.316 7.34658 106.911 7.87103C107.505 8.39548 107.839 9.10679 107.839 9.84848V40.2456C107.839 40.9873 107.505 41.6986 106.911 42.223C106.316 42.7475 105.509 43.0421 104.668 43.0421H99.4287C98.6894 43.042 97.9733 42.8143 97.4042 42.3982C96.8351 41.982 96.4487 41.4037 96.3117 40.7631C95.4178 41.3321 94.4658 41.8268 93.4682 42.2406C92.3663 42.6968 91.212 43.047 90.0251 43.2853C87.5921 43.7712 85.0666 43.7712 82.6336 43.2853H82.6347ZM84.7642 18.6898C82.8669 19.394 81.3341 20.6948 80.4633 22.3397C79.5925 23.9845 79.4458 25.8561 80.0518 27.5909C80.6578 29.3257 81.9732 30.8001 83.7426 31.7278C85.5121 32.6554 87.6092 32.9702 89.6271 32.611C91.6449 32.2518 93.4393 31.2442 94.6621 29.7838C95.8848 28.3234 96.4485 26.5145 96.2438 24.7083C96.0391 22.902 95.0806 21.2272 93.5543 20.0091C92.0281 18.791 90.043 18.1165 87.9845 18.1165C86.8784 18.1161 85.7835 18.311 84.7642 18.6898Z"
                    fill="#02BAFF"
                />
                <path
                    d="M120.387 7.05194C121.228 7.05194 122.034 7.34658 122.629 7.87103C123.224 8.39548 123.558 9.10679 123.558 9.84848V10.6341C124.436 10.0793 125.37 9.59626 126.347 9.1908C127.449 8.73476 128.604 8.38456 129.79 8.14624C132.223 7.66051 134.749 7.66051 137.182 8.14624C138.369 8.38491 139.523 8.73548 140.624 9.1919C141.724 9.64817 142.767 10.2022 143.739 10.8455C144.722 11.4961 145.63 12.229 146.453 13.0341C147.291 13.8556 148.04 14.7442 148.692 15.6882C149.359 16.6563 149.925 17.676 150.383 18.7346C150.854 19.826 151.211 20.9529 151.452 22.1013C151.948 24.491 151.948 26.9404 151.452 29.3301C151.212 30.4784 150.854 31.6052 150.383 32.6965C149.926 33.7552 149.36 34.7749 148.692 35.7429C148.041 36.6871 147.291 37.5758 146.453 38.3974C145.63 39.2029 144.721 39.9362 143.739 40.5871C142.767 41.2304 141.723 41.7845 140.624 42.2407C139.522 42.6969 138.368 43.0471 137.181 43.2853C134.748 43.771 132.223 43.771 129.79 43.2853C128.604 43.0467 127.45 42.6961 126.348 42.2396C125.37 41.8345 124.436 41.3518 123.558 40.7974V56.0521C123.558 56.7938 123.224 57.5051 122.629 58.0295C122.034 58.554 121.228 58.8486 120.387 58.8486H115.147C114.306 58.8486 113.5 58.554 112.905 58.0295C112.31 57.5051 111.976 56.7938 111.976 56.0521V9.84848C111.976 9.10679 112.31 8.39548 112.905 7.87103C113.5 7.34658 114.306 7.05194 115.147 7.05194H120.387ZM135.051 32.1338C136.948 31.4296 138.481 30.1287 139.352 28.4839C140.223 26.8391 140.37 24.9675 139.764 23.2327C139.158 21.4979 137.842 20.0235 136.073 19.0958C134.303 18.1682 132.206 17.8534 130.188 18.2126C128.171 18.5718 126.376 19.5794 125.153 21.0398C123.931 22.5001 123.367 24.3091 123.572 26.1153C123.776 27.9216 124.735 29.5964 126.261 30.8145C127.787 32.0326 129.772 32.7071 131.831 32.7071C132.937 32.7077 134.032 32.5128 135.051 32.1338Z"
                    fill="#02BAFF"
                />
                <path
                    d="M35.1752 43.0422C34.3341 43.0422 33.5277 42.7476 32.933 42.2231C32.3383 41.6987 32.0039 40.9873 32.0039 40.2457V36.3548C32.0039 36.1772 32.0229 36 32.0607 35.8255C32.1084 35.3355 32.3025 34.8653 32.623 34.4628L52.4578 9.4839H35.5888C34.7478 9.4839 33.9414 9.18929 33.3466 8.66484C32.7519 8.14039 32.4176 7.42905 32.4176 6.68736V2.79653C32.4176 2.05485 32.7519 1.34351 33.3466 0.81906C33.9414 0.294608 34.7478 0 35.5888 0H64.9577C65.7988 0 66.6054 0.294608 67.2002 0.81906C67.7949 1.34351 68.129 2.05485 68.129 2.79653V6.33055C68.1924 6.81855 68.1086 7.31259 67.8858 7.76278C67.7647 8.01862 67.601 8.25719 67.4002 8.47058L47.4809 33.5583H64.5446C65.3857 33.5583 66.1923 33.8529 66.787 34.3774C67.3818 34.9018 67.7159 35.6131 67.7159 36.3548V40.2457C67.7159 40.9873 67.3818 41.6987 66.787 42.2231C66.1923 42.7476 65.3857 43.0422 64.5446 43.0422H35.1752Z"
                    fill="#02BAFF"
                />
                <path
                    d="M162.992 7.05194C163.833 7.05194 164.64 7.34658 165.235 7.87103C165.829 8.39548 166.164 9.10679 166.164 9.84848V10.6341C167.042 10.0793 167.975 9.59626 168.953 9.1908C170.055 8.73476 171.209 8.38456 172.396 8.14624C174.829 7.66051 177.354 7.66051 179.787 8.14624C180.974 8.38491 182.128 8.73548 183.23 9.1919C184.329 9.64817 185.373 10.2022 186.345 10.8455C187.327 11.4961 188.236 12.229 189.058 13.0341C189.896 13.8556 190.646 14.7442 191.297 15.6882C191.965 16.6563 192.531 17.676 192.988 18.7346C193.459 19.826 193.817 20.9529 194.057 22.1013C194.554 24.491 194.554 26.9404 194.057 29.3301C193.817 30.4784 193.459 31.6052 192.988 32.6965C192.531 33.7552 191.965 34.7749 191.297 35.7429C190.646 36.6871 189.896 37.5758 189.058 38.3974C188.236 39.2029 187.327 39.9362 186.344 40.5871C185.372 41.2304 184.329 41.7845 183.229 42.2407C182.127 42.6969 180.973 43.0471 179.786 43.2853C177.354 43.771 174.829 43.771 172.396 43.2853C171.209 43.0467 170.055 42.6961 168.953 42.2396C167.976 41.8345 167.042 41.3518 166.164 40.7974V56.0521C166.164 56.7938 165.829 57.5051 165.235 58.0295C164.64 58.554 163.833 58.8486 162.992 58.8486H157.753C156.912 58.8486 156.105 58.554 155.51 58.0295C154.916 57.5051 154.581 56.7938 154.581 56.0521V9.84848C154.581 9.10679 154.916 8.39548 155.51 7.87103C156.105 7.34658 156.912 7.05194 157.753 7.05194H162.992ZM177.657 32.1338C179.554 31.4296 181.087 30.1287 181.958 28.4839C182.828 26.8391 182.975 24.9675 182.369 23.2327C181.763 21.4979 180.448 20.0235 178.678 19.0958C176.909 18.1682 174.812 17.8534 172.794 18.2126C170.776 18.5718 168.982 19.5794 167.759 21.0398C166.536 22.5001 165.972 24.3091 166.177 26.1153C166.382 27.9216 167.34 29.5964 168.867 30.8145C170.393 32.0326 172.378 32.7071 174.436 32.7071C175.543 32.7077 176.638 32.5128 177.657 32.1338Z"
                    fill="#02BAFF"
                />
                <path
                    d="M40.7138 17.0765L43.4357 13.2197H7.25V17.0765H40.7138Z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M34.8394 25.4329L37.5488 21.5762H13.6777V25.4329H34.8394Z"
                    fill="url(#paint1_linear)"
                />
                <path
                    d="M29.4842 33.1458L32.1528 29.2891H20.748V33.1458H29.4842Z"
                    fill="url(#paint2_linear)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="42.6037"
                    y1="12.5769"
                    x2="7.25"
                    y2="12.5769"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02BAFF" />
                    <stop
                        offset="0.59375"
                        stopColor="#02BAFF"
                        stopOpacity="0.583333"
                    />
                    <stop offset="1" stopColor="#02BAFF" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="37.6678"
                    y1="20.2906"
                    x2="13.6777"
                    y2="20.2906"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02BAFF" />
                    <stop
                        offset="0.59375"
                        stopColor="#02BAFF"
                        stopOpacity="0.567708"
                    />
                    <stop offset="1" stopColor="#02BAFF" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="31.4689"
                    y1="28.0035"
                    x2="20.1052"
                    y2="28.0035"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02BAFF" />
                    <stop
                        offset="0.5625"
                        stopColor="#02BAFF"
                        stopOpacity="0.59375"
                    />
                    <stop offset="1" stopColor="#02BAFF" stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="200" height="58.8486" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
