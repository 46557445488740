export const BarcodeBlackSmallIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M 1.4437 1.309 V 7.5652 H 3.9462 V 2.5602 H 8.9512 V 0.0578 H 2.6949 C 2.003 0.0578 1.4437 0.6171 1.4437 1.309 Z M 23.9662 7.5652 V 1.309 C 23.9662 0.6171 23.4068 0.0578 22.7149 0.0578 H 16.4587 V 2.5602 H 21.4637 V 7.5652 H 23.9662 Z M 21.4637 20.0778 H 16.4587 V 22.5803 H 22.7149 C 23.4068 22.5803 23.9662 22.021 23.9662 21.329 V 15.0727 H 21.4637 V 20.0778 Z M 8.9512 22.5803 V 20.0778 H 3.9462 V 15.0727 H 1.4437 V 21.329 C 1.4437 22.021 2.003 22.5803 2.6949 22.5803 H 8.9512 Z M 0.1924 10.0677 H 25.2173 V 12.5703 H 0.1924 V 10.0677 Z"
                fill="#454545"
            />
        </svg>
    )
}
