import {
    Box,
    Button,
    Card,
    CircularProgress,
    Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/styles'
import { Component, ErrorInfo, ReactNode, Suspense } from 'react'
import Page from './Components/Page/Page'
import { ReloadButton } from './Components/Page/ReloadButton'
import { ROUTES } from './consts/Routes'
import { firebase } from './firebase'
import { log } from './utils/logHelper'

const INITIAL_STATE = {
    hasError: false,
    message: '',
    stack: '',
}
const styles = () => ({
    card: {
        width: '600px',
        padding: '30px',
        background: '#fdfafa',
    },
    inActiveUser: {
        marginTop: '40vh',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
})

interface Props {
    children: ReactNode
    classes: any
}

interface State {
    hasError: boolean
    message: string
    stack: string
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = INITIAL_STATE

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            message: error?.message ?? '',
            stack: error?.stack ?? '',
        }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        log('uncaught-error', error.message, {
            error,
            errorInfo,
            url: window.location.href,
        })
    }

    refresh = () => {
        this.setState(INITIAL_STATE)
        window.location.reload()
    }

    logout = () => {
        firebase.auth().signOut()
        localStorage.clear()
        localStorage.clear()
        window.location.href = ROUTES.LOGIN
    }

    public render() {
        const { classes } = this.props
        if (this.state.hasError) {
            return (
                <div className={classes.inActiveUser}>
                    <Card className={classes.card}>
                        <Alert severity="error">
                            <Typography>
                                Something went wrong, please try again later.
                            </Typography>

                            <Box mt={1} component="details">
                                <summary>Error: {this.state.message}</summary>
                                <Typography variant="caption">
                                    {this.state.stack}
                                </Typography>
                            </Box>
                        </Alert>

                        <Box mt={2} display="flex" justifyContent="center">
                            <Button onClick={this.refresh} color="primary">
                                Refresh
                            </Button>
                            <Button onClick={this.logout} color="secondary">
                                Logout
                            </Button>
                        </Box>
                    </Card>
                </div>
            )
        }

        return (
            <Suspense
                fallback={
                    <Page
                        header={<Box>Loading...</Box>}
                        footer={<ReloadButton />}
                        showFooter={true}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            height="50vh"
                        >
                            <CircularProgress />
                        </Box>
                    </Page>
                }
            >
                {this.props.children}
            </Suspense>
        )
    }
}

export default withStyles(styles as any)(ErrorBoundary)
