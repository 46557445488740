import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const PlusIcon = (props: SvgIconProps) => {
    const { fill } = props
    return (
        <SvgIcon {...props}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.847 4.5105L12 4.5C12.2719 4.50001 12.5345 4.59846 12.7394 4.77715C12.9443 4.95584 13.0775 5.20267 13.1145 5.472L13.125 5.625V10.875H18.375C18.6469 10.875 18.9095 10.9735 19.1144 11.1522C19.3193 11.3308 19.4525 11.5777 19.4895 11.847L19.5 12C19.5 12.2719 19.4015 12.5345 19.2228 12.7394C19.0442 12.9443 18.7973 13.0775 18.528 13.1145L18.375 13.125H13.125V18.375C13.125 18.6469 13.0265 18.9095 12.8478 19.1144C12.6692 19.3193 12.4223 19.4525 12.153 19.4895L12 19.5C11.7281 19.5 11.4655 19.4015 11.2606 19.2228C11.0557 19.0442 10.9225 18.7973 10.8855 18.528L10.875 18.375V13.125H5.625C5.35314 13.125 5.09049 13.0265 4.8856 12.8478C4.68072 12.6692 4.54747 12.4223 4.5105 12.153L4.5 12C4.50001 11.7281 4.59846 11.4655 4.77715 11.2606C4.95584 11.0557 5.20267 10.9225 5.472 10.8855L5.625 10.875H10.875V5.625C10.875 5.35314 10.9735 5.09049 11.1522 4.8856C11.3308 4.68072 11.5777 4.54747 11.847 4.5105L12 4.5L11.847 4.5105Z"
                    fill={`${fill ? fill : '#444'}`}
                />
            </svg>
        </SvgIcon>
    )
}
