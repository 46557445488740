import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const DeleteIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.5 5H4.16667H17.5"
                    stroke="#FF0000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.66669 4.99996V3.33329C6.66669 2.89127 6.84228 2.46734 7.15484 2.15478C7.4674 1.84222 7.89133 1.66663 8.33335 1.66663H11.6667C12.1087 1.66663 12.5326 1.84222 12.8452 2.15478C13.1578 2.46734 13.3334 2.89127 13.3334 3.33329V4.99996M15.8334 4.99996V16.6666C15.8334 17.1087 15.6578 17.5326 15.3452 17.8451C15.0326 18.1577 14.6087 18.3333 14.1667 18.3333H5.83335C5.39133 18.3333 4.9674 18.1577 4.65484 17.8451C4.34228 17.5326 4.16669 17.1087 4.16669 16.6666V4.99996H15.8334Z"
                    stroke="#FF0000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.33331 9.16663V14.1666"
                    stroke="#FF0000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.6667 9.16663V14.1666"
                    stroke="#FF0000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    )
}
