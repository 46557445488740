import { useEffect, useState } from 'react'
import { getLocalUserData } from '../utils/dataStore'
import { auth, firebase } from './'

/**
 * Custom hook to check to get the current firebase user
 */
export const useUser = (fn?: () => void) => {
    const [authLoaded, setAuthLoaded] = useState<boolean>(false)
    const [user, setUser] = useState<firebase.User | undefined>(
        auth.currentUser ?? undefined
    )

    useEffect(() => {
        const observer = auth.onAuthStateChanged(function (user) {
            setUser(user ?? undefined)
            setAuthLoaded(true)
            fn?.()
        })

        return observer
    }, [fn])

    return {
        user,
        isLoaded: authLoaded,
        isLoggedIn: !!user && !!getLocalUserData()?.accessToken,
    }
}
