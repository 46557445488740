import { OrderDetails } from '@quickcommerceltd/zapp-app-utils'
import { Action, IConfig } from 'overmind'
import { createHook } from 'overmind-react'
import { Product } from '../../types'

type State = {
    order: OrderDetails | null
    products: Product[]
}

type ResetBasketParams = {
    index: number
    quantity: number
}

const initialState: State = {
    order: null,
    products: [],
}

const setOrder: Action<OrderDetails> = ({ state }, order) => {
    state.order = order
}
const setProducts: Action<Product[]> = ({ state }, products) => {
    state.products = products
}

const addToBasket: Action<{ index: number; isOversupplyAllowed?: boolean }> = (
    { state },
    { index, isOversupplyAllowed = false }
) => {
    const product = state.products[index]
    if (
        product.quantityInBasket < product.quantity ||
        state?.order?.isCycleCount ||
        isOversupplyAllowed
    ) {
        product.quantityInBasket += 1
    }
    product.outOfStock = false
}

const addMultipleToBasket: Action<ResetBasketParams> = (
    { state },
    { index, quantity }
) => {
    const product = state.products[index]
    if (quantity <= product.quantity || state?.order?.isCycleCount) {
        product.quantityInBasket = quantity
    }
    product.outOfStock = false
}

const removeFromBasket: Action<number> = ({ state }, index) => {
    const product = state.products[index]
    product.quantityInBasket -= 1
    product.outOfStock = false
}

const setOutOfStock: Action<number> = ({ state }, index) => {
    const product = state.products[index]
    product.outOfStock = true
}
const unsetOutOfStock: Action<number> = ({ state }, index) => {
    const product = state.products[index]
    product.outOfStock = false
}
const setBarCode: Action<{ index: number; barcode: string }> = (
    { state },
    { index, barcode }
) => {
    const product = state.products[index]
    if (!product.barcode) {
        product.barcode = ''
    }
    product.barcode = product.barcode
        ? product.barcode + ' ' + barcode
        : barcode
}

const undo: Action<number> = ({ state }, index) => {
    const product = state.products[index]
    if (product.outOfStock) {
        product.outOfStock = false
    } else {
        if (product.quantityInBasket > 0) {
            product.quantityInBasket -= 1
        }
    }
}

const resetBasket: Action<ResetBasketParams> = ({ state }, data) => {
    const { index, quantity } = data
    const product = state.products[index]
    product.quantityInBasket = quantity
    product.outOfStock = false
}

const clear: Action = ({ state }) => {
    state.products = []
    state.order = null
}

const setQuantityToBasket: Action<ResetBasketParams> = (
    { state },
    { index, quantity }
) => {
    if (index > -1) {
        const product = state.products[index]
        product.quantityInBasket = quantity > -1 ? quantity : 0
        product.outOfStock = false
    }
}

const addIndefiniteItemToBasket: Action<{
    index: number
    quantity?: number
}> = ({ state }, { index, quantity }) => {
    const product = state.products[index]
    if (quantity || quantity === 0) {
        product.quantityInBasket = quantity
    } else {
        product.quantityInBasket += 1
    }
    product.outOfStock = false
}

const removeIndefiniteItemFromBasket: Action<number> = ({ state }, index) => {
    const product = state.products[index]
    if (product.quantityInBasket > 0) {
        product.quantityInBasket -= 1
        product.outOfStock = false
    }
}

export const config = {
    state: initialState,
    actions: {
        setOrder,
        setProducts,
        addToBasket,
        addMultipleToBasket,
        removeFromBasket,
        setOutOfStock,
        unsetOutOfStock,
        setBarCode,
        undo,
        clear,
        resetBasket,
        setQuantityToBasket,
        addIndefiniteItemToBasket,
        removeIndefiniteItemFromBasket,
    },
}

declare module 'overmind' {
    interface Config
        extends IConfig<{
            state: typeof config.state
            actions: typeof config.actions
            // effects: typeof config.effects
        }> {}
}

export const useOvermind = createHook()
