import { createContext, useContext, useState } from 'react'
import ZSnack, { AlertSeverity } from '../../Components/ZSnack/ZSnack'

interface ToastParams {
    message: string
    severity?: AlertSeverity
    autoHideDuration?: number
}

export interface ToastActions {
    show: (params: ToastParams) => void
    hide: () => void
}

const defaultActons = { show: () => {}, hide: () => {} }

const defaultValue = {
    show: false,
    message: '',
    severity: AlertSeverity.SUCCESS,
    autoHideDuration: 5000,
}

const ToastContext = createContext<ToastActions>(defaultActons)

export const useToast = () => useContext(ToastContext)

const ToastProvider: React.FC = ({ children }) => {
    const [visible, toggle] = useState(false)
    const [state, setState] = useState<ToastParams>(defaultValue)
    const show = (params: ToastParams) => {
        setState(params)
        toggle(true)
    }
    const hide = () => toggle(false)
    const toast = { show, hide }

    return (
        <ToastContext.Provider value={toast}>
            {children}
            <ZSnack
                show={visible}
                onClose={hide}
                message={state.message}
                severity={state.severity}
                autoHideDuration={state.autoHideDuration}
            />
        </ToastContext.Provider>
    )
}

export default ToastProvider
