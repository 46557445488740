import React from 'react'
import AppStateContext from './context'
import useProvider from './useProvider'

export interface Props {
    children?: React.ReactNode
}

const Provider: React.FC<Props> = ({ children }) => {
    const { value } = useProvider()

    return (
        <AppStateContext.Provider value={value}>
            {children}
        </AppStateContext.Provider>
    )
}

export default Provider
