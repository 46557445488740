export const BackArrowIcon = () => {
    return (
        <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.8125 1.375L1.1875 7L6.8125 12.625"
                stroke="#444444"
                strokeWidth="1.875"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
