import { useEffect, useState } from 'react'
import { REQUEST_STATUS } from '../../consts/requestStatus'
import { zimBackendRemoteConfig } from '../../firebase'
import type { ContextValue, FeatureFlagMap } from './types'

const useProvider = () => {
    const [fetchFeatureFlagsRequestStatus, setFetchFeatureFlagsRequestStatus] =
        useState(REQUEST_STATUS.NOT_INITIALISED)
    const [featureFlags, setFeatureFlags] = useState<FeatureFlagMap>({})

    const fetchFeatureFlags = async () => {
        setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.PENDING)

        try {
            await zimBackendRemoteConfig.fetchAndActivate()

            const newFeatureFlags = Object.entries(
                zimBackendRemoteConfig.getAll()
            ).reduce((acc: FeatureFlagMap, [key, value]) => {
                acc[key] = JSON.parse(value.asString())

                return acc
            }, {})

            setFeatureFlags(newFeatureFlags)
            setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.FULFILLED)
        } catch (error) {
            setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.REJECTED)
        }
    }

    useEffect(() => {
        fetchFeatureFlags()
    }, [])

    const value: ContextValue = featureFlags

    return {
        fetchFeatureFlagsRequestStatus,
        fetchFeatureFlags,
        value,
    }
}

export default useProvider
