interface Props {
    width?: number
    height?: number
}

export const NoImage = ({ width, height }: Props) => {
    return (
        <svg
            width={width || '100'}
            height={height || '100'}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="100" height="100" fill="white" />
            <path
                d="M83.815 20.3742L80.4408 17L17 80.4408L20.3742 83.815L25.1467 79.0425H74.27C75.5352 79.0406 76.748 78.5372 77.6426 77.6426C78.5372 76.748 79.0406 75.5352 79.0425 74.27V25.1467L83.815 20.3742ZM74.27 74.27H29.9192L48.5152 55.674L54.1921 61.3508C55.0871 62.2455 56.3008 62.7482 57.5663 62.7482C58.8317 62.7482 60.0454 62.2455 60.9404 61.3508L64.725 57.5662L74.27 67.1041V74.27ZM74.27 60.3534L68.0992 54.1825C67.2042 53.2878 65.9905 52.7852 64.725 52.7852C63.4595 52.7852 62.2458 53.2878 61.3508 54.1825L57.5663 57.9671L51.8941 52.295L74.27 29.9192V60.3534Z"
                fill="#BDBDBD"
            />
            <path
                d="M26.544 64.725V57.5662L38.4752 45.6421L41.7516 48.9208L45.1305 45.5419L41.8494 42.2608C40.9544 41.3661 39.7407 40.8635 38.4752 40.8635C37.2097 40.8635 35.9961 41.3661 35.1011 42.2608L26.544 50.8179V26.545H64.724V21.7725H26.544C25.2786 21.7737 24.0655 22.2769 23.1707 23.1717C22.276 24.0664 21.7727 25.2796 21.7715 26.545V64.725H26.544Z"
                fill="#BDBDBD"
            />
        </svg>
    )
}
