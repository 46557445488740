import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const DownloadIcon = (props: SvgIconProps) => {
    const fill = props.fill || '#fff'
    return (
        <SvgIcon {...props}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M12 16L16 11H13V4H11V11H8L12 16Z" fill={fill} />
                <path
                    d="M20 18H4V11H2V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V11H20V18Z"
                    fill={fill}
                />
            </svg>
        </SvgIcon>
    )
}
