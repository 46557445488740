export const BarcodeBlue = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="70"
            fill="none"
            viewBox="0 0 90 70"
        >
            <path
                fill="#444"
                d="M6.563 4.375a2.188 2.188 0 00-2.188 2.188v13.125a2.187 2.187 0 11-4.375 0V6.563A6.563 6.563 0 016.563 0h13.125a2.187 2.187 0 110 4.375H6.563zm61.562-2.188A2.188 2.188 0 0170.313 0h13.124A6.562 6.562 0 0190 6.563v13.125a2.188 2.188 0 01-4.375 0V6.563a2.188 2.188 0 00-2.188-2.188H70.314a2.187 2.187 0 01-2.188-2.188zM2.187 48.126a2.188 2.188 0 012.188 2.188v13.124a2.187 2.187 0 002.188 2.188h13.125a2.188 2.188 0 010 4.375H6.563A6.563 6.563 0 010 63.437V50.313a2.187 2.187 0 012.188-2.188zm85.626 0A2.188 2.188 0 0190 50.313v13.124A6.563 6.563 0 0183.437 70H70.314a2.188 2.188 0 010-4.375h13.124a2.188 2.188 0 002.188-2.188V50.313a2.188 2.188 0 012.188-2.188z"
            ></path>
            <path
                fill="#2F80ED"
                d="M23.766 18.14c-.41.41-.641.967-.641 1.547v30.625a2.188 2.188 0 004.375 0V19.689a2.188 2.188 0 00-3.734-1.547zM32.516 18.14c-.41.41-.641.967-.641 1.547v30.625a2.188 2.188 0 004.375 0V19.689a2.188 2.188 0 00-3.734-1.547zM41.266 18.14c-.41.41-.641.967-.641 1.547v30.625a2.188 2.188 0 004.375 0V19.689a2.188 2.188 0 00-3.734-1.547zM50.016 18.14c-.41.41-.641.967-.641 1.547v30.625a2.188 2.188 0 002.188 2.188h4.374a2.188 2.188 0 002.188-2.188V19.689a2.188 2.188 0 00-2.188-2.188h-4.374c-.58 0-1.137.23-1.547.64zM63.14 18.14c-.41.41-.64.967-.64 1.547v30.625a2.188 2.188 0 004.375 0V19.689a2.188 2.188 0 00-3.734-1.547z"
            ></path>
        </svg>
    )
}
