import { WarehouseConfig } from '@quickcommerceltd/zapp-app-utils'
import { WarehouseType } from '../types'

export const defaultWarehouse: WarehouseConfig = {
    id: 'foo',
    color: 'green',
    pin: '1000',
    onfleetTeamId: '123',
    shopifyLocationId: 'abcd',
    saleorWarehouseId: 'saleorWarehouse123',
    shortName: 'short warehouse name',
    isDownloading: true,
    shouldAllowDownload: true,
    slackWebHook: 'https://goodplace.com/goodnotficiation',
    variantListUrl: 'https://ontheinternet.com/somecdn/vlist.json',
    paths: [],
    aisles: [],
    type: WarehouseType.STORE,
}

export const makeWarehouse = (
    override?: Partial<WarehouseConfig>
): WarehouseConfig => ({
    ...defaultWarehouse,
    ...override,
})

export const cypressWarehouses = [
    makeWarehouse({
        id: 'de_cypress_test',
        color: 'green',
        pin: 'cypress',
    }),
    makeWarehouse({
        id: 'de_cypress_test_2',
        color: 'yellow',
        pin: 'cypress',
    }),
]
