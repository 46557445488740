import { createBrowserHistory } from 'history'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../consts/Routes'
import { useAppState } from '../Containers/AppState'
import { setLocalUserData } from '../utils/dataStore'
import { setDeviceStatus } from '../utils/deviceStatus'
import { log } from '../utils/logHelper'
import { auth, zimBackendAuth } from './'

export const useLogout = () => {
    const history = useHistory()
    const { user, userDetails } = useAppState()
    const logout = () => {
        log('auth-logout', 'logout clicked', {
            email: auth.currentUser?.email,
        })
        auth.signOut()
        zimBackendAuth.signOut()
        setLocalUserData()
        setDeviceStatus({
            isOnline: false,
            pushToken: undefined,
            userId: user?.uid,
            userEmail: userDetails?.email,
            userName: userDetails?.name,
            warehouseId: userDetails?.warehouseId,
        })
        history.push(ROUTES.LOGIN)
    }

    return {
        logout,
    }
}

export function logout() {
    const history = createBrowserHistory()
    auth.signOut()
    setLocalUserData()
    history.push(ROUTES.LOGIN)
}
