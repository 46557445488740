export const OfflineErrorImage = () => {
    return (
        <svg
            width="215"
            height="196"
            viewBox="0 0 215 196"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                opacity="0.2"
                cx="97.5"
                cy="98"
                r="97.5"
                transform="rotate(-90 97.5 98)"
                fill="white"
            />
            <circle
                opacity="0.2"
                cx="117.5"
                cy="98"
                r="97.5"
                transform="rotate(-90 117.5 98)"
                fill="white"
            />
            <path
                d="M113.133 106.793H103.535L102.023 71.8125H114.645L113.133 106.793ZM101.672 117.691C101.672 115.957 102.293 114.539 103.535 113.438C104.801 112.312 106.371 111.75 108.246 111.75C110.121 111.75 111.68 112.312 112.922 113.438C114.188 114.539 114.82 115.957 114.82 117.691C114.82 119.426 114.188 120.855 112.922 121.98C111.68 123.082 110.121 123.633 108.246 123.633C106.371 123.633 104.801 123.082 103.535 121.98C102.293 120.855 101.672 119.426 101.672 117.691Z"
                fill="white"
            />
        </svg>
    )
}
