import { Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

export enum AlertSeverity {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

interface IProps {
    show: boolean
    message: string
    autoHideDuration?: number
    onClose: () => void
    severity?: AlertSeverity
    style?: object
}

export interface ISnack {
    show: boolean
    message: string
    severity?: AlertSeverity
}

const ZSnack = (props: IProps) => {
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    return (
        <Snackbar
            open={props.show}
            autoHideDuration={props.autoHideDuration || 6000}
            onClose={props.onClose}
            style={props.style}
        >
            <Alert
                onClose={props.onClose}
                severity={props.severity || AlertSeverity.SUCCESS}
            >
                {props.message}
            </Alert>
        </Snackbar>
    )
}

export default ZSnack
