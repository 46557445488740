import { useAppState } from '../AppState'
import { RoleBasedFeatureFlagValue } from './types'
import useFeatureFlag from './useFeatureFlag'

export interface UserRoleFeatureFlags {
    values: any
    config: any
    warehouseId?: string
    userRole?: string
}

export type RoleBasedFeatureFlags = 'stockAdjustmentLimitsV2'

const useUserRoleFeatureFlag = (
    flags: RoleBasedFeatureFlags[]
): UserRoleFeatureFlags => {
    const { values, config, warehouseId } = useFeatureFlag(
        flags,
        useAppState().warehouseId
    )
    const userRole = useAppState().userDetails?.userRole

    const outValues: any = {}
    for (const key in values) {
        const roleBasedValue = values[key] as RoleBasedFeatureFlagValue
        outValues[key] =
            !!userRole && userRole in roleBasedValue
                ? roleBasedValue[userRole]
                : roleBasedValue.all ?? false
    }

    return {
        values: outValues,
        config,
        warehouseId,
        userRole,
    } as UserRoleFeatureFlags
}

export default useUserRoleFeatureFlag
