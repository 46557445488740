import { grey } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import shadows, { Shadows } from '@material-ui/core/styles/shadows'
import { FC } from 'react'

// This is our custom shadow
export const zShadow = '0px 4px 15px rgba(0, 0, 0, 0.25)'

// Let's add this shadow as the last shadow
const zShadows = [...shadows] as Shadows
zShadows[24] = zShadow
// log({ shadows })
// log({ zShadows })

const zColors = {
    primary: '#51b7f6',
    primaryLight: '#8ce9ff',
    secondary: '#8bc34a',
}

export const COLORS = {
    lightRed: '#fd1004',
    lightGreen: '#55aa68',
    green: '#1be426',
    lightOrange: '#f19c4d',
    pinkColor: '#f1b7b5',
    autoGeneratedPO: '#01ad4a78',
    autoGeneratedPOdelayed: '#de4f4f8a',
}

const theme = createTheme({
    palette: {
        background: {
            default: '#fff',
        },
        primary: {
            light: '#8ce9ff',
            main: '#51b7f6',
            dark: '#0087c3',
            contrastText: '#fff',
        },
        secondary: {
            light: '#bef67a',
            main: '#8bc34a',
            dark: '#5a9216',
            contrastText: '#000',
        },
        grey: { 100: '#F2F2F2' },
    },
    shadows: zShadows,
    typography: {
        allVariants: {
            color: '#444444',
        },
        h4: {
            fontSize: 30,
            fontWeight: 700,
        },
        h5: {
            fontWeight: 500,
        },
        //   h6: {
        //     fontWeight: 700,
        //   },
    },
    overrides: {
        MuiButton: {
            root: {
                fontWeight: 500,
            },
            sizeLarge: {
                borderRadius: 10,
                paddingTop: 16,
                paddingBottom: 16,
            },
            contained: {
                boxShadow: zShadow,
                '&$disabled': {
                    color: grey[400],
                    backgroundColor: grey[200],
                },
            },
        },
        MuiMenuItem: {
            root: {
                borderRadius: 0,
            },
        },
        MuiListItem: {
            root: {
                borderRadius: 10,
                '&$selected': {
                    backgroundColor: zColors.primary,
                    color: '#fff',
                },
                '&$button': {
                    '&:hover': {
                        backgroundColor: zColors.primary,
                        color: '#fff',
                    },
                },
            },
            // selected: {
            //   backgroundColor: zColors.primary,
            // },
        },
        MuiOutlinedInput: {
            adornedEnd: {
                paddingRight: 4,
            },
        },
    },
})

export const Theme: FC = ({ children }) => (
    <ThemeProvider theme={theme}>
        <>
            <CssBaseline />
            {children}
        </>
    </ThemeProvider>
)
