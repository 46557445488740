import {
    FirestoreCollection,
    WarehouseConfig,
} from '@quickcommerceltd/zapp-app-utils'
import { useState } from 'react'
import { Country } from '../Containers/CountryProvider/CountryProvider'
import { useApolloProviderContext } from '../GQL/ApolloClientProvider'
import { isUserDetails, UserDetails } from '../types/UserDetails'
import dataStore, { setLocalUserData } from '../utils/dataStore'
import { setDeviceStatus } from '../utils/deviceStatus'
import { log } from '../utils/logHelper'
import { auth, db, zimAnalytics, zimBackendAuth, zimFunctions } from '.'

/**
 * Hook for email password login
 */
export const useLogin = () => {
    const [loginState, setLoginState] = useState<
        | 'submitting'
        | 'loggedin'
        | 'not-loggedin'
        | 'not-allowed'
        | 'needs-sign-up'
    >('not-loggedin')
    const { setApolloAccessToken } = useApolloProviderContext()
    const [errorState, setErrorState] = useState('')

    const login = async (
        email: string,
        password: string,
        country: Country
    ): Promise<void> => {
        setLoginState('submitting')

        try {
            const login = zimFunctions.httpsCallable('api-iam-login')

            const {
                data: { id, zimBackendToken, sharedBackendToken, graphQLToken },
            } = await login({ email, password })

            await Promise.all([
                zimBackendAuth.signInWithCustomToken(zimBackendToken),
                auth.signInWithCustomToken(sharedBackendToken),
            ])

            setApolloAccessToken(graphQLToken)

            const userDetailsDoc = await db
                .collection(`${country.toLowerCase()}_users`)
                .doc(id)
                .get()

            if (userDetailsDoc.exists) {
                const userDetails = userDetailsDoc.data() as UserDetails
                if (isUserDetails(userDetails)) {
                    setLocalUserData({
                        userDetails,
                    })
                }

                // setting up warehouse
                await db
                    .collection(FirestoreCollection.WAREHOUSE_CONFIGS)
                    .doc(userDetails.warehouseId)
                    .get()
                    .then((doc) => {
                        const warehouse = doc.data() as WarehouseConfig
                        if (warehouse) {
                            dataStore.setWarehouse(warehouse)
                        }
                    })

                setLocalUserData({
                    uid: id as string,
                    email,
                    accessToken: graphQLToken as string,
                })
                await setDeviceStatus({
                    isOnline: true,
                    pushToken: undefined,
                    userId: id,
                    userEmail: userDetails.email,
                    userName: userDetails.name,
                    warehouseId: userDetails.warehouseId,
                })
                zimAnalytics.logEvent('login', {})
                setLoginState('loggedin')
            } else {
                setLoginState('needs-sign-up')
            }
        } catch (error) {
            setLoginState('not-loggedin')
            log('login', 'error', error)
            if (error instanceof Error) {
                setErrorState(error.message)
            }
        }
    }
    return {
        loginState,
        login,
        errorState,
    }
}
