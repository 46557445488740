import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { withCheckLogin } from './Components/CheckLogin/CheckLogin'
import { ROUTES } from './consts/Routes'
import Login from './Containers/Login/Login'
import OfflinePage from './Containers/OfflinePage'

const DashboardPage = lazy(() => import('./Containers/Dashboard/Dashboard'))

const Putaway = lazy(() => import('./Containers/Putaway/Putaway'))
const PutawayItems = lazy(
    () => import('./Containers/PutawayItems/PutawayItems')
)

const Profile = lazy(() => import('./Containers/Profile/Profile'))
const SignupComplete = lazy(() => import('./Containers/SignUp/SignUpComplete'))
const WarehouseSecurity = lazy(
    () => import('./Containers/WarehouseSelect/WarehouseSecurity')
)
const WarehouseSelect = lazy(
    () => import('./Containers/WarehouseSelect/WarehouseSelect')
)

const InboundPOSearch = lazy(() => import('./Containers/InboundPO/InboundPO'))
const InboundPOItems = lazy(
    () => import('./Containers/InboundPO/InboundPOItems/InboundPOItems')
)
const InboundV2 = lazy(() => import('./Containers/InboundV2'))
const InboundV2PurchaseOrder = lazy(
    () => import('./Containers/InboundV2/InboundPurchaseOrder')
)
const InboundV2Item = lazy(() => import('./Containers/InboundV2/InboundItem'))
const InboundPOInput = lazy(
    () => import('./Containers/InboundProducts/InboundPOInput')
)
const CycleCount = lazy(() => import('./Containers/CycleCount/CycleCount'))
const AssignSKULocation = lazy(
    () => import('./Containers/AssignSKULocation/AssignSKULocation')
)
const QueueListener = lazy(() => import('./Containers/QueueListener'))
const Task = lazy(() => import('./Containers/Task'))
const Orders = lazy(() => import('./Containers/Orders/Orders'))

const Picking = lazy(() => import('./Containers/Picking/Picking'))

const SkuLocations = lazy(
    () => import('./Containers/SkuLocations/SkuLocations')
)

const AdjustStockV2 = lazy(
    () => import('./Containers/StockAdjustmentV2/AdjustStock')
)
const MoveSKUV2 = lazy(() => import('./Containers/StockAdjustmentV2/MoveSKU'))

const audio = new Audio(
    'https://firebasestorage.googleapis.com/v0/b/project-corner.appspot.com/o/zoee-new-order-sound.mp3?alt=media&token=e1727c9d-5c0c-4a2f-bd4a-14c0c84a39db'
)

const PickingV2 = lazy(() => import('./Containers/PickingV2'))
const PickingV2ProductList = lazy(
    () => import('./Containers/PickingV2/PickingProductList')
)
const PickingV2Success = lazy(
    () => import('./Containers/PickingV2/PickingSuccess')
)

const StockMovementExplorer = lazy(
    () => import('./Containers/StockMovementExplorer/StockMovementExplorer')
)

const ExpirySweeper = lazy(
    () => import('./Containers/ExpirySweeper/ExpirySweeper')
)

const DiscrepancySweeper = lazy(
    () => import('./Containers/DiscrepancySweeper/DiscrepancySweeper')
)

const DiscrepancySweeperDetails = lazy(
    () => import('./Containers/DiscrepancySweeper/DiscrepancySweeperDetails')
)
const Debugging = lazy(() => import('./Containers/Debugging'))

audio.loop = true

interface Props {
    children?: React.ReactNode
}

function Router({ children }: Props) {
    return (
        <Switch>
            <Route
                exact
                path={ROUTES.INDEX}
                component={withCheckLogin(() => {
                    const smallScreen = window.innerWidth <= 800 // useMediaQuery returns wrong value at first render!
                    if (smallScreen) {
                        return <Redirect to={ROUTES.QUEUE_LISTENING} />
                    } else {
                        return <Redirect to={ROUTES.PURCHASE_ORDERS} />
                    }
                })}
            />
            <Route exact path={ROUTES.LOGIN} component={Login} />
            <Route
                exact
                path={ROUTES.SIGNUP_COMPLETE}
                component={SignupComplete}
            />
            <Route
                exact
                path={ROUTES.PROFILE}
                component={withCheckLogin(Profile)}
            />
            <Route
                exact
                path={ROUTES.SKU_ANALYSIS}
                component={withCheckLogin(DashboardPage)}
            />
            {/* FOR ZOEE WEB APP */}

            <Route
                exact
                path={ROUTES.DASHBOARD}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.UPDATE_PRODUCT_LIST}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.UPDATE_PRODUCT_LIST_TABLE}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.PURCHASE_ORDERS}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.PURCHASE_ORDER_DETAIL}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.WAREHOUSE_LOCATIONS}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.STOCK_ADJUSTMENT}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.STOCK_ADJUSTMENT_V2_ADJUST_STOCK}
                component={withCheckLogin(AdjustStockV2)}
            />
            <Route
                exact
                path={ROUTES.STOCK_ADJUSTMENT_V2_MOVE_SKU}
                component={withCheckLogin(MoveSKUV2)}
            />
            <Route
                exact
                path={ROUTES.USERS}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.LOCATIONS_V2}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.LOCATIONS_V3}
                component={withCheckLogin(DashboardPage)}
            />
            <Route
                exact
                path={ROUTES.STOCK_CHECKS}
                component={withCheckLogin(DashboardPage)}
            />
            {/* FOR PICKER APP */}
            <Route path={ROUTES.ORDERS} component={withCheckLogin(Orders)} />

            <Route
                exact
                path={ROUTES.QUEUE_LISTENING}
                render={withCheckLogin(() => (
                    <QueueListener audio={audio} />
                ))}
            />

            <Route
                exact
                path={ROUTES.TASK_PERMALINK}
                render={withCheckLogin(() => (
                    <Task />
                ))}
            />

            <Route path={ROUTES.PICKLIST} component={withCheckLogin(Orders)} />

            <Route
                exact
                path={ROUTES.WAREHOUSE_SECURITY}
                component={withCheckLogin(WarehouseSecurity)}
            />
            <Route
                exact
                path={ROUTES.INBOUND_PO_NO}
                component={withCheckLogin(InboundPOInput)}
            />
            <Route
                exact
                path={ROUTES.PICKING}
                component={withCheckLogin(Picking)}
            />
            <Route
                exact
                path={ROUTES.INBOUND_PO_SEARCH}
                component={withCheckLogin(InboundPOSearch)}
            />
            <Route
                exact
                path={ROUTES.INBOUND_PO_ITEMS}
                component={withCheckLogin(InboundPOItems)}
            />
            <Route
                exact
                path={ROUTES.INBOUND_V2}
                render={withCheckLogin(InboundV2)}
            />
            <Route
                exact
                path={ROUTES.INBOUND_V2_PURCHASE_ORDER}
                render={withCheckLogin(InboundV2PurchaseOrder)}
            />
            <Route
                exact
                path={ROUTES.INBOUND_V2_ITEM}
                render={withCheckLogin(InboundV2Item)}
            />
            <Route
                exact
                path={ROUTES.PUTAWAY}
                render={withCheckLogin(Putaway)}
            />
            <Route
                exact
                path={ROUTES.PUTAWAY_ALL}
                render={withCheckLogin(PutawayItems)}
            />
            <Route
                exact
                path={ROUTES.PUTAWAY_ITEMS}
                render={withCheckLogin(PutawayItems)}
            />
            <Route
                exact
                path={ROUTES.PO_SKU_LOCATIONS}
                component={withCheckLogin(SkuLocations)}
            />
            <Route
                exact
                path={ROUTES.PICKING_V2}
                render={withCheckLogin(PickingV2)}
            />
            <Route
                exact
                path={ROUTES.PICKING_V2_PRODUCT_LIST}
                render={withCheckLogin(PickingV2ProductList)}
            />
            <Route
                exact
                path={ROUTES.PICKING_V2_SUCCESS}
                render={withCheckLogin(PickingV2Success)}
            />
            <Route
                exact
                path={ROUTES.STOCK_MOVEMENT_EXPLORER}
                render={withCheckLogin(StockMovementExplorer)}
            />
            <Route
                exact
                path={ROUTES.STOCK_MOVEMENT_EXPLORER_DETAILS}
                render={withCheckLogin(StockMovementExplorer)}
            />

            <Route
                exact
                path={ROUTES.DISCREPANCY_SWEEPER}
                render={withCheckLogin(DiscrepancySweeper)}
            />
            <Route
                exact
                path={ROUTES.DISCREPANCY_SWEEPER_DETAILS}
                render={withCheckLogin(DiscrepancySweeperDetails)}
            />
            <Route
                exact
                path={ROUTES.EXPIRY_SWEEPER}
                render={withCheckLogin(ExpirySweeper)}
            />
            <Route
                exact
                path={ROUTES.PO_SKU_LOCATIONS_SEARCH}
                component={withCheckLogin(SkuLocations)}
            />
            <Route
                exact
                path={ROUTES.CYCLE_COUNT}
                component={withCheckLogin(CycleCount)}
            />
            <Route
                exact
                path={ROUTES.ASSIGN_SKU_LOCATION}
                component={withCheckLogin(AssignSKULocation)}
            />
            <Route
                path={ROUTES.WAREHOUSE_SELECTION}
                component={WarehouseSelect}
            />
            <Route path={ROUTES.DEBUGGING} component={Debugging} />

            {children}

            <Route exact path="/error" component={OfflinePage} />
        </Switch>
    )
}

export default Router
