import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    root: {
        backgroundColor: '#ff0102',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100vh',
        '& .error-detail': {
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 50px',
            textAlign: 'center',
        },
        '& .error-title': {
            fontSize: 30,
            color: '#ffffff',
        },
        '& .error-desc': {
            fontSize: 18,
            color: '#e5e5e5',
        },
    },
})

const ErrorPageContainerStyle: React.FC = ({ children }) => {
    const classes = useStyles()
    return <div className={classes.root}>{children}</div>
}

export default ErrorPageContainerStyle
