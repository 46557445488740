import {
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { Logo } from '../../Components/Svg/Logo'
import { LogoMobile } from '../../Components/Svg/LogoMobile'
import { ROUTES } from '../../consts/Routes'
import { useLogin } from '../../firebase/useLogin'
import { Country, useCountry } from '../CountryProvider/CountryProvider'

const emailSuffix = process.env.REACT_APP_EMAIL_SUFFIX ?? '@quickcommerce.org'

const useStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        loginContent: {
            background: '#ffffff',
            borderTopLeftRadius: theme.spacing(4),
            borderTopRightRadius: theme.spacing(4),
            padding: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '500px',
            width: '100%',
            justifyContent: 'space-between',
        },
        select: {
            border: '1px solid #c4c4c4',
            padding: '11px',
            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(2.5),
            borderRadius: theme.spacing(1),
        },
    }),
    {
        name: 'Login',
    }
)

const Login = ({ history }: any) => {
    const classes = useStyles()
    const { login, loginState, errorState } = useLogin()
    const [email, setEmail] = useState(process.env.REACT_APP_LOGIN_EMAIL || '')
    const [password, setPassword] = useState(
        process.env.REACT_APP_LOGIN_PASSWORD || ''
    )
    const proxyLoginWithValidatedCredentials = useCallback(
        (email: string, password: string, userCountry: Country) => {
            const emailWithSuffix = email.endsWith(emailSuffix)
                ? email
                : email + emailSuffix
            login(emailWithSuffix, password, userCountry)
        },
        [login]
    )

    const { userCountry, setUserCountry } = useCountry()

    const smallScreen = useMediaQuery('(max-width:800px)')

    useEffect(() => {
        if (loginState === 'needs-sign-up') {
            history.push(`${ROUTES.SIGNUP_COMPLETE}?email=${email}`)
        } else if (loginState === 'loggedin') {
            smallScreen
                ? history.push(ROUTES.QUEUE_LISTENING)
                : history.push(ROUTES.PURCHASE_ORDERS)
        }
    }, [email, history, loginState, smallScreen])

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            proxyLoginWithValidatedCredentials(email, password, userCountry)
        }
    }

    const handleOnChange = useCallback(
        (country: Country) => {
            setUserCountry(country)
        },
        [setUserCountry]
    )

    return (
        <Box className={classes.root}>
            <Box pt={16} display="flex" justifyContent="center">
                {smallScreen ? (
                    <LogoMobile fillColor="white" />
                ) : (
                    <Logo fillColor="white" />
                )}
            </Box>

            <Box className={classes.loginContent}>
                <Typography variant="h5">Welcome!</Typography>

                <Box mb={4} />
                {loginState === 'not-allowed' && (
                    <>
                        <Alert severity="error">
                            Email or password is not correct.
                        </Alert>
                        <Box mb={4} />
                    </>
                )}
                {errorState && errorState !== 'submitting' && (
                    <>
                        <Alert severity="error">{errorState}</Alert>
                        <Box mb={4} />
                    </>
                )}
                {/* we want to check false instead of undefined because we want to wait until firebase is ready */}
                <TextField
                    id="email"
                    label="Email"
                    type="text"
                    variant="outlined"
                    value={email}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {emailSuffix}
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Box mb={4} />
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    autoComplete="false"
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Box mb={4} />
                <Select
                    displayEmpty
                    disableUnderline
                    value={userCountry.toUpperCase()}
                    onChange={(event) => {
                        handleOnChange(event.target.value as Country)
                    }}
                    className={classes.select}
                >
                    <MenuItem value={''}>Select a Country</MenuItem>
                    {Object.keys(Country).map((c: string) => {
                        return (
                            <MenuItem key={c} value={c}>
                                {c}
                            </MenuItem>
                        )
                    })}
                </Select>
                <Box mb={4} />

                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disableElevation={true}
                    disabled={loginState === 'submitting' || !userCountry}
                    onClick={() =>
                        proxyLoginWithValidatedCredentials(
                            email,
                            password,
                            userCountry
                        )
                    }
                >
                    {loginState === 'submitting' ? (
                        <CircularProgress
                            style={{ color: 'white' }}
                            size={20}
                        />
                    ) : (
                        'Login'
                    )}
                </Button>
                <Box mb={4} />
            </Box>
        </Box>
    )
}

export default Login
