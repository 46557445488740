import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const CrossHairIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.0004 2.3999C5.69741 2.3999 1.39941 6.6979 1.39941 11.9999C1.39928 13.2608 1.64753 14.5093 2.12998 15.6742C2.61243 16.8391 3.31963 17.8976 4.21119 18.7891C5.10275 19.6807 6.16121 20.3879 7.32611 20.8703C8.49102 21.3528 9.73956 21.601 11.0004 21.6009C16.3014 21.6009 20.6004 17.3029 20.6004 11.9999C20.6004 6.6979 16.3014 2.3999 11.0004 2.3999ZM12.0004 19.5249V14.9999H10.0004V19.5249C8.34654 19.3059 6.8109 18.5481 5.63105 17.3686C4.4512 16.1891 3.69288 14.6537 3.47341 12.9999H8.00041V10.9999H3.47341C3.69288 9.34609 4.4512 7.81069 5.63105 6.6312C6.8109 5.45171 8.34654 4.69387 10.0004 4.4749V8.9999H12.0004V4.4749C13.6539 4.69443 15.189 5.45251 16.3684 6.63193C17.5478 7.81136 18.3059 9.34645 18.5254 10.9999H14.0004V12.9999H18.5254C18.3056 14.6532 17.5474 16.1881 16.368 17.3675C15.1887 18.5469 13.6537 19.305 12.0004 19.5249Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
)
