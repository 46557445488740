import { Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { Logo } from '../../Components/Svg/Logo'
import { LogoMobile } from '../../Components/Svg/LogoMobile'

const useStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    }),
    {
        name: 'Maintenance',
    }
)

const Maintenance = () => {
    const classes = useStyles()

    const smallScreen = useMediaQuery('(max-width:800px)')

    return (
        <Box className={classes.root}>
            <Box pt={16} display="flex" justifyContent="center">
                {smallScreen ? (
                    <LogoMobile fillColor="white" />
                ) : (
                    <Logo fillColor="white" />
                )}
            </Box>
            <Box mt={16}>
                <Typography
                    variant="h4"
                    style={{ color: 'white' }}
                    align="center"
                >
                    ZIM/ZIPP is under maintenance, please check back later.
                </Typography>
            </Box>
        </Box>
    )
}

export default Maintenance
