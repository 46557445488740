import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const LocationsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 22"
            fill={props.htmlColor || 'currentColor'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.966 16.244c-.31.324-.66.616-1.028.855a.599.599 0 1 1-.65-1.008c.88-.569 1.54-1.474 1.692-2.147a.6.6 0 0 1 1.186.132V21a.6.6 0 0 1-1.2 0v-4.756ZM9.496 14.676c-.756 0-1.313.652-1.313 1.289a.6.6 0 1 1-1.2 0c0-1.25 1.044-2.488 2.513-2.488.865 0 1.704.391 2.174 1.091.492.732.514 1.696-.068 2.652-.295.486-.7.854-1.1 1.16-.2.153-.407.296-.604.43l-.084.058c-.168.115-.329.224-.482.336-.518.376-.887.727-1.052 1.196h3.105a.6.6 0 0 1 0 1.2H7.583a.6.6 0 0 1-.6-.6c0-1.4.844-2.186 1.642-2.766.17-.125.348-.245.515-.36l.081-.055c.196-.133.38-.262.552-.394.348-.264.62-.527.804-.828.373-.615.293-1.068.097-1.36-.216-.322-.654-.56-1.178-.56v-.001ZM14.866 14.99c-.16.137-.277.316-.338.516a.6.6 0 1 1-1.151-.34c.127-.424.374-.802.71-1.089.419-.356 1.008-.6 1.793-.6 1.59 0 2.528 1.123 2.528 2.173 0 .414-.084 1.048-.525 1.584a2.106 2.106 0 0 1-.26.268c.15.11.29.246.412.41.24.324.372.728.372 1.2 0 .856-.299 1.512-.822 1.94-.504.41-1.136.548-1.704.548-.54 0-1.067-.086-1.52-.364-.465-.285-.781-.727-.977-1.302a.6.6 0 1 1 1.136-.386c.126.37.292.557.468.665.191.116.468.187.892.187.396 0 .726-.098.947-.278.199-.162.381-.45.381-1.01a.784.784 0 0 0-.139-.488.937.937 0 0 0-.378-.283c-.36-.163-.818-.203-1.133-.203a.6.6 0 1 1 0-1.2c.855 0 1.224-.254 1.398-.466.192-.232.252-.543.252-.822 0-.336-.348-.973-1.328-.973-.522 0-.83.156-1.014.312v.001ZM2.793 9.9a.9.9 0 0 1 .9-.9h17.4a.9.9 0 0 1 0 1.8h-17.4a.9.9 0 0 1-.9-.9ZM2.793 3.9a.9.9 0 0 1 .9-.9h17.4a.9.9 0 0 1 0 1.8h-17.4a.9.9 0 0 1-.9-.9ZM19.593 15.9a.9.9 0 0 1 .9-.9h.6a.9.9 0 1 1 0 1.8h-.6a.9.9 0 0 1-.9-.9Z" />
        </SvgIcon>
    )
}
