import { FirestoreCollection } from '@quickcommerceltd/zapp-app-utils'
import { createContext, FC, useContext, useState } from 'react'
import { getWarehouse } from '../../utils/dataStore'

interface CountryProviderValue {
    setUserCountry: (country: Country) => void
    userCountry: Country
    SALEOR_URL: string
}

export enum Country {
    'GB' = 'GB',
    'NL' = 'NL',
    'FR' = 'FR',
}

export const COUNTRY_SALEOR_MAP: Record<Country, string> = {
    GB: process.env.REACT_APP_SALEOR_URL_UK as string,
    NL: process.env.REACT_APP_SALEOR_URL_NL as string,
    FR: process.env.REACT_APP_SALEOR_URL_FR as string,
}

// TODO Remove once country is static at build time
const getCountryFromURL = (): Country => {
    switch (window.location.host) {
        case 'nl.zim.quickcommerce.org':
            return Country.NL
        default:
            return Country.GB
    }
}

// this is used to get firebase data ex.. gb_purchaseOrders
let USER_COUNTRY: Country = getCountryFromURL()

const CountryContext = createContext<CountryProviderValue>({
    setUserCountry: (country: Country) => {
        //No-op
        Function.prototype(country)
    },
    userCountry: getCountryFromURL(),
    SALEOR_URL: process.env.REACT_APP_SALEOR_URL_NL as string,
})

export const CountryProvider: FC = (props) => {
    const [userCountry, setUserCountryState] =
        useState<Country>(getCountryFromURL)

    const value: CountryProviderValue = {
        setUserCountry: (country: Country) => {
            setUserCountryState(country)
            USER_COUNTRY = country
        },
        userCountry,
        SALEOR_URL: COUNTRY_SALEOR_MAP[userCountry.toUpperCase() as Country],
    }

    return (
        <CountryContext.Provider value={value}>
            {props.children}
        </CountryContext.Provider>
    )
}

export function useCountry() {
    return useContext(CountryContext)
}

export const getCountryPrefix = (warehouseId: string) => {
    const id = warehouseId || getWarehouse().id

    const countryPrefix: string = id.substring(0, 3)

    const countryPrefixMapped: string =
        countryPrefix === 'uk_' ? 'gb_' : countryPrefix // This mapping is due to the fact that we have historical data with uk_ warehouseId and we didn't want to lose that. Talk to Tobias Schultka, Martin Anders or Aemal Sayer to know more.

    return countryPrefixMapped
}

export const getVariantDownloadListCollection = () => {
    return (
        USER_COUNTRY.toLowerCase() +
        '_' +
        FirestoreCollection.VARIANT_DOWNLOAD_LIST
    )
}

export const getStockAdjustmentsCollection = () => {
    return (
        USER_COUNTRY.toLowerCase() + '_' + FirestoreCollection.STOCK_ADJUSTMENTS
    )
}

export const getUsersCollection = () => {
    return USER_COUNTRY.toLowerCase() + '_' + FirestoreCollection.USERS
}

export const getPurchaseOrdersCollection = () => {
    return (
        USER_COUNTRY.toLowerCase() + '_' + FirestoreCollection.PURCHASE_ORDERS
    )
}

export const getStockHistoryCollection = () => {
    return USER_COUNTRY.toLowerCase() + '_stockHistory'
}

export const getSkuLocationCollection = () => {
    return USER_COUNTRY.toLowerCase() + '_skuLocations'
}

export const getEventsCollection = () => {
    return USER_COUNTRY.toLowerCase() + '_events'
}

export const getExpiryDateCollection = () => {
    return USER_COUNTRY.toLowerCase() + '_expiryDates'
}
