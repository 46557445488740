export const WifiIcon = () => {
    return (
        <svg
            width="50%"
            height="50%"
            viewBox="0 0 93 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.91675 23.5833C28.6667 -6.14035 64.3334 -6.14035 91.0834 23.5833"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.75 41.4167C35.8 23.5833 57.2 23.5833 73.25 41.4167"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M46.5001 63.7083C48.9624 63.7083 50.9584 61.7123 50.9584 59.25C50.9584 56.7877 48.9624 54.7917 46.5001 54.7917C44.0378 54.7917 42.0417 56.7877 42.0417 59.25C42.0417 61.7123 44.0378 63.7083 46.5001 63.7083Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
