import { Box, CircularProgress, CssBaseline, Hidden } from '@material-ui/core'
import { CSSProperties, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Route } from 'react-router-dom'

import { withCheckLogin } from './Components/CheckLogin/CheckLogin'
import CommonProviders from './Components/CommonProviders/CommonProviders'
import InternetCheck from './Components/InternetCheck/InternetCheck'
import Page from './Components/Page/Page'
import { ReloadButton } from './Components/Page/ReloadButton'
import SideDrawer from './Components/SideDrawer/SideDrawer'
import { APP_VERSION } from './consts/version'
import { useAppState } from './Containers/AppState'
import {
    getCountryPrefix,
    useCountry,
} from './Containers/CountryProvider/CountryProvider'
import Maintenance from './Containers/Maintenance/Maintenance'
import ErrorBoundary from './ErrorBoundary'
import { db, initZimBackendApp } from './firebase'
import { useUser } from './firebase/useUser'
import Router from './Router'
import { getLocalUserData } from './utils/dataStore'
import { isDevEnv } from './utils/env'

function App() {
    const [authLoaded, setAuthLoaded] = useState(false)
    const [isZimZippInMaintenance, setZimZippInMaintenance] = useState(false)
    const { userDetails, warehouseConfig } = useAppState()

    const { userCountry } = useCountry()
    useEffect(() => {
        initZimBackendApp(userCountry)
    }, [userCountry])

    useEffect(() => {
        const localUserData = getLocalUserData()
        if (authLoaded && localUserData?.userDetails?.warehouseId) {
            const country = getCountryPrefix(
                localUserData?.userDetails?.warehouseId
            ).substring(0, 2)
            const subscriber = db
                .collection('countries')
                .doc(country)
                .onSnapshot((snapshot) => {
                    setZimZippInMaintenance(
                        !!snapshot.data()?.isZimZippInMaintenance
                    )
                })
            return () => subscriber()
        }
    }, [authLoaded])

    useUser(() => setAuthLoaded(true))

    if (!authLoaded) {
        return (
            <Page
                header={<Box>Auth...</Box>}
                footer={<ReloadButton />}
                showFooter={true}
            >
                <CssBaseline />
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="50vh"
                >
                    <CircularProgress />
                </Box>
            </Page>
        )
    }

    const StatusBar = () => {
        const fields = [
            isDevEnv && process.env.REACT_APP_FIREBASE_CONFIG,
            userDetails?.email.split('@').shift(),
            warehouseConfig?.shortName,
            APP_VERSION,
        ]
        const style = {
            fontSize: '0.75rem',
            position: 'fixed',
            right: '.5rem',
            color: '#555',
            zIndex: 1,
            top: 0,
        } as CSSProperties
        return (
            <span style={style}>{fields.filter((s) => !!s).join(' • ')}</span>
        )
    }

    return (
        <ErrorBoundary>
            <CommonProviders>
                <Helmet>
                    <title>ZIM</title>
                </Helmet>
                <StatusBar />

                <Hidden smUp>
                    <SideDrawer />
                </Hidden>

                <Router>
                    {isZimZippInMaintenance && (
                        <Route
                            exact
                            path={'*'}
                            component={withCheckLogin(Maintenance)}
                        />
                    )}
                </Router>

                <InternetCheck />
            </CommonProviders>
        </ErrorBoundary>
    )
}

export default App
