// Firebase App (the core Firebase SDK) is always required and must be listed first
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'
// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/remote-config'
import 'firebase/storage'

import firebase from 'firebase/app'
import { Country } from '../Containers/CountryProvider/CountryProvider'
import {
    firebaseConfig,
    getZimBackendConfig,
    getZimFunctionsRegion,
} from './firebaseConfig'

let sharedBackend: firebase.app.App
let zimBackend: firebase.app.App
let zimAnalytics: firebase.analytics.Analytics
let zimFunctions: firebase.functions.Functions
let zimBackendRemoteConfig: firebase.remoteConfig.RemoteConfig
let zimBackendDb: firebase.firestore.Firestore
let zimBackendAuth: firebase.auth.Auth

if (firebase.apps.length === 0) {
    sharedBackend = firebase.initializeApp(firebaseConfig)
    // firebase.functions().useEmulator("localhost", 5001)
} else {
    sharedBackend = firebase.app()
}

export const initZimBackendApp = async (country: Country) => {
    if (zimBackend) {
        await firebase.app('zimBackend').delete()
    }
    const newCountry = country.toUpperCase() as Country
    const config = getZimBackendConfig(newCountry)
    zimBackend = firebase.initializeApp(config, 'zimBackend')

    zimAnalytics = zimBackend.analytics()

    const functionsRegion = getZimFunctionsRegion(newCountry)
    zimFunctions = zimBackend.functions(functionsRegion)
    if (process.env.REACT_APP_FIREBASE_CONFIG === 'localhost') {
        zimFunctions.useEmulator('localhost', 5001)
    }
    zimBackendDb = zimBackend.firestore()
    zimBackendDb.settings({ ignoreUndefinedProperties: true })

    zimBackendAuth = zimBackend.auth()

    zimBackendRemoteConfig = zimBackend.remoteConfig()
    zimBackendRemoteConfig.settings.minimumFetchIntervalMillis = 600_000 // 10 minutes
    zimBackendRemoteConfig.defaultConfig = {
        isLocationV2Enabled: '{}',
        deviceHeartbeatIntervalSeconds: '{"all":60}',
        stockAdjustmentLimits: '{}',
        stockAdjustmentLimitsV2: '{}',
        isSAValidationEnabled: '{}',
        isPickingInStoresEnabled: '{}',
        isInboundSLAEnabled: '{}',
        isZimStockAdjustmentEnabled: '{"all":true}',
        isStockAdjustmentFromZeroEnabled: '{}',
        isAssignSkuEnabled: '{"all":true}',
        isStockByLocationEnabled: '{}',
        isZippStockAdjustmentEnabled: '{"all":true}',
        userAbleToMapLocation: '{}',
        isStockChecksEnabled: '{}',
        isPurchaseOrderV2Enabled: '{}',
    }
}

const authProvider = new firebase.auth.GoogleAuthProvider() // uses default project
const auth = sharedBackend.auth()
const firestore = sharedBackend.firestore()
const db = sharedBackend.firestore()
db.settings({ ignoreUndefinedProperties: true })
const storage = sharedBackend.storage()

export {
    firebase,
    sharedBackend,
    zimBackend,
    zimAnalytics,
    zimBackendDb,
    zimFunctions,
    zimBackendRemoteConfig,
    zimBackendAuth,
    authProvider,
    firestore,
    db,
    auth,
    storage,
}
