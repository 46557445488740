import { useContext } from 'react'
import { getWarehouse } from '../../utils/dataStore'
import FeatureFlagContext from './context'
import { getFeatureFlagValues } from './utilities'

const useFeatureFlag = (
    flags: string[],
    warehouseId: string = getWarehouse().id
) => {
    const config = useContext(FeatureFlagContext)

    return {
        values: getFeatureFlagValues(flags, warehouseId, config),
        config,
        warehouseId,
    }
}

export default useFeatureFlag
