import { Box, CircularProgress } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../consts/Routes'
import { zimBackendAuth } from '../../firebase'
import { useUser } from '../../firebase/useUser'

const CheckLogin = ({ children }: { children: React.ReactNode }) => {
    const { isLoggedIn } = useUser()
    const [zimUser, isLoading] = useAuthState(zimBackendAuth)
    const history = useHistory()

    useEffect(() => {
        if (!isLoggedIn || (!isLoading && !zimUser)) {
            localStorage.clear()
            sessionStorage.clear()
            history.replace(ROUTES.LOGIN)
        }
    }, [history, isLoggedIn, isLoading, zimUser])

    if (!isLoggedIn) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="50vh"
            >
                <CircularProgress />
            </Box>
        )
    }

    return <>{children}</>
}

export default CheckLogin

/**
 * HOC for easy usage.
 *
 * withCheckLogin(SomeRouteComponent)
 */
export const withCheckLogin =
    (Component: React.ComponentType): React.VFC =>
    (props) =>
        (
            <CheckLogin>
                <Component {...props} />
            </CheckLogin>
        )
